export const enum ViewModes {
  List = 'List',
  Grid = 'Grid',
}

export const enum PagingTypes {
  Links = 'Links',
  LoadMore = 'LoadMore',
  ContinuousScrolling = 'ContinuousScrolling',
  LoadMoreContent = 'LoadMoreContent',
}