import { PageComponentNames } from 'behavior/pages';
import FaqPage from './Page';

export default {
  [PageComponentNames.Faq]: page => (
    <FaqPage
      heading={page.heading}
      headerText={page.headerText}
      footerText={page.footerText}
      faqItems={page.faqItems}
      showLeftMenu={page.showLeftMenu}
    />
  ),
};
