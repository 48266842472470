import type { MainMenuItemWithParent } from './types';
import {
  PAGE_NAVIGATION_ITEMS_LOADED,
  PageNavigationAction,
} from './actions';

export type State = {
  navigationItems?: MainMenuItemWithParent[];
};

export default function navigationReducer(state: State, action: PageNavigationAction) {
  if (action.type === PAGE_NAVIGATION_ITEMS_LOADED)
    return { ...state, navigationItems: action.payload.items };

  return state;
}