import type { CategoriesTexts } from './types';

export interface ProductWithCategories {
  categoriesPaths?: null | Array<{
    categories: Array<{ name: string }>;
  }>;
}

export default function (product: ProductWithCategories, categoriesTexts: CategoriesTexts) {
  const { categoriesPaths } = product;

  if (!categoriesPaths?.length)
    return undefined;

  const categoryPath = categoriesPaths.map(path => path.categories.map(c => c.name).join(' > '))[0];
  return { category: enrichCategoryPathWithSanaText(categoryPath, categoriesTexts) };
}

function enrichCategoryPathWithSanaText(categoryPath: string, texts: CategoriesTexts): string {
  if (categoryPath.toLowerCase().includes('software'))
    return texts.software || 'Software > Computer Software > Business & Productivity Software';

  if (categoryPath.toLowerCase().includes('books'))
    return texts.books || 'Media > Books > Non-Fiction > Reference Books';

  if (categoryPath.toLowerCase().includes('foot protection'))
    return texts.footProtection || 'Apparel & Accessories > Shoes > Outdoor Shoes';

  if (categoryPath.toLowerCase().includes('body protection'))
    return texts.bodyProtection || 'Business & Industrial > Protective Equipment > Hazardous Material Suits';

  if (categoryPath.toLowerCase().includes('eye protection'))
    return texts.eyeProtection || 'Business & Industrial > Protective Equipment > Protective Eyewear';

  if (categoryPath.toLowerCase().includes('hand protection'))
    return texts.handProtection || 'Apparel & Accessories > Clothing Accessories > Gloves & Mittens';

  if (categoryPath.toLowerCase().includes('head protection'))
    return texts.headProtection || 'Business & Industrial > Protective Equipment > Hardhats';

  return categoryPath;
}
