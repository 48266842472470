import loadable from '@loadable/component';
import { Media } from 'components/responsive';
import { usePrintMode } from 'utils/hooks';

const DesktopDetailsTemplate = loadable(() => import(/*webpackChunkName:"pdp"*/'./DesktopDetailsTemplate'));
const MobileDetailsTemplate = loadable(() => import(/*webpackChunkName:"pdp"*/'./MobileDetailsTemplate'));
const PrintModeTemplate = loadable(() => import(/*webpackChunkName:"pdp"*/'./PrintModeTemplate'));

const DetailsPage = () => {
  const isPrintMode = usePrintMode();
  if (isPrintMode)
    return <PrintModeTemplate />;

  return (
    <>
      <Media at="xs">
        <MobileDetailsTemplate />
      </Media>
      <Media greaterThanOrEqual="sm">
        <DesktopDetailsTemplate />
      </Media>
    </>
  );
};

export default DetailsPage;
