import { Steps } from './constants';
import { trackCheckoutOption, trackPaymentInfo, trackShippingInfo } from 'behavior/analytics/actions';

const stepsNames = Object.values(Steps);

export const getStepNumber = step => stepsNames.indexOf(step) + 1;

export const getTrackingActionsForStep = (step, info) => {
  switch (step) {
    case Steps.Address:
      return [trackCheckoutOption({
        step: getStepNumber(step),
        option: info.shippingAddress?.shippingOption,
      })];
    case Steps.Shipping:
      const shippingMethod = getMethod(info.shippingMethods, info.shippingMethodId);
      if (!shippingMethod)
        return [];
      return [
        trackCheckoutOption({
          step: getStepNumber(step),
          option: shippingMethod,
        }),
        trackShippingInfo({
          shippingMethod,
          products: info.analytics?.products || [],
          promotion: info.discount.promotion,
        }),
      ];
    case Steps.Payment:
      const paymentMethod = getMethod(info.paymentMethods, info.paymentMethodId);
      if (!paymentMethod)
        return [];
      return [
        trackCheckoutOption({
          step: getStepNumber(step),
          option: paymentMethod,
        }),
        trackPaymentInfo({
          paymentMethod,
          products: info.analytics?.products || [],
          promotion: info.discount.promotion,
        }),
      ];
    default:
      return [];
  }
};

export const getMethod = (methods, methodId) => {
  const method = methods &&
    methods.find(method => method.id === methodId);
  return method && method.name;
};

export const getTrackingActionsForCheckoutStart = (checkoutInfo, isMultiStep) => {
  const analytics = checkoutInfo.analytics;

  if (isMultiStep || !analytics || !analytics.products.length)
    return [];

  const actions = [];
  const { discount, paymentMethodId, shippingMethodId } = checkoutInfo;

  if (paymentMethodId) {
    const paymentMethod = getMethod(checkoutInfo.paymentMethods, paymentMethodId);

    actions.push(trackPaymentInfo({
      paymentMethod,
      products: analytics.products,
      promotion: discount?.promotion,
    }));
  }

  if (shippingMethodId) {
    const shippingMethod = getMethod(checkoutInfo.shippingMethods, shippingMethodId);

    actions.push(trackShippingInfo({
      shippingMethod,
      products: analytics.products,
      promotion: discount?.promotion,
    }));
  }

  return actions;
};
