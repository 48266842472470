import type { ProductLine, Totals } from './types';
import type { AppState } from 'behavior';
import type { Epic } from 'behavior/types';
import type { LoadedSettings } from 'behavior/settings';
import { merge, EMPTY } from 'rxjs';
import {
  ignoreElements,
  pluck,
  switchMap,
  filter,
  delayWhen,
  first,
  share,
  tap,
  mergeMap,
  map,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  AnalyticsAction,
  ANALYTICS_PRODUCT_CLICKED,
  ANALYTICS_PRODUCT_DETAILS_VIEWED,
  ANALYTICS_PRODUCT_LIST_VIEWED,
  ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
  ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
  ANALYTICS_CHECKOUT,
  ANALYTICS_CHECKOUT_START,
  ANALYTICS_CHECKOUT_OPTION,
  ANALYTICS_PURCHASE,
  ANALYTICS_PAYMENT_INFO,
  ANALYTICS_SHIPPING_INFO,
} from './actions';
import { documentDetailsQuery, setTrackedMutation } from './queries';
import { skipIfPreview } from 'behavior/preview';
import { getDataLayer } from './dataLayer';

type Document = {
  lines: {
    itemLines: Array<ProductLine>;
  };
  totals: Totals | null;
  exists: boolean;
  documentId: string;
  email: string | null;
};

type DocumentsResponse = {
  paymentTransaction: {
    document: Document;
  };
};

const analyticsEpic: Epic<AnalyticsAction> = (action$, state$, { api }) => {
  const dataLayerReady$ = state$.pipe(
    first(({ analytics }) => analytics!.isDataLayerReady!),
  );

  const delayedAction$ = action$.pipe(
    skipIfPreview(state$),
    filter(_ => !!state$.value.analytics?.isTrackingEnabled),
    ofType(
      ANALYTICS_PRODUCT_CLICKED,
      ANALYTICS_PRODUCT_DETAILS_VIEWED,
      ANALYTICS_PRODUCT_LIST_VIEWED,
      ANALYTICS_PRODUCTS_ADDED_TO_BASKET,
      ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET,
      ANALYTICS_CHECKOUT,
      ANALYTICS_CHECKOUT_START,
      ANALYTICS_CHECKOUT_OPTION,
      ANALYTICS_PAYMENT_INFO,
      ANALYTICS_SHIPPING_INFO,
      ANALYTICS_PURCHASE,
    ),
    delayWhen(_ => dataLayerReady$),
    share(),
  );

  return merge(
    delayedAction$.pipe(
      tap(action => {
        const state = state$.value;

        const {
          productClick,
          productDetailsView,
          productListView,
          productsAddToBasket,
          productsRemoveFromBasket,
          checkout,
          checkoutStart,
          checkoutOption,
          paymentInfoAdded,
          shippingInfoAdded,
        } = getDataLayer(state);

        switch (action.type) {
          case ANALYTICS_PRODUCT_CLICKED:
            productClick.isSupported && productClick.push(action.payload);
            break;
          case ANALYTICS_PRODUCT_DETAILS_VIEWED:
            productDetailsView.isSupported && productDetailsView.push(action.payload);
            break;
          case ANALYTICS_PRODUCT_LIST_VIEWED:
            productListView.isSupported && productListView.push(action.payload);
            break;
          case ANALYTICS_PRODUCTS_ADDED_TO_BASKET:
            productsAddToBasket.isSupported && productsAddToBasket.push(action.payload);
            break;
          case ANALYTICS_PRODUCTS_REMOVED_FROM_BASKET:
            productsRemoveFromBasket.isSupported && productsRemoveFromBasket.push(action.payload);
            break;
          case ANALYTICS_CHECKOUT:
            checkout.isSupported && checkout.push(action.payload);
            break;
          case ANALYTICS_CHECKOUT_START:
            checkoutStart.isSupported && checkoutStart.push(action.payload);
            break;
          case ANALYTICS_CHECKOUT_OPTION:
            checkoutOption.isSupported && checkoutOption.push(action.payload);
            break;
          case ANALYTICS_PAYMENT_INFO:
            paymentInfoAdded.isSupported && paymentInfoAdded.push(action.payload);
            break;
          case ANALYTICS_SHIPPING_INFO:
            shippingInfoAdded.isSupported && shippingInfoAdded.push(action.payload);
            break;
          default:
            break;
        }
      }),
      ignoreElements(),
    ),
    delayedAction$.pipe(
      ofType(ANALYTICS_PURCHASE),
      pluck('payload', 'transaction'),
      map(transaction => {
        const purchase = getDataLayer(state$.value).purchase;
        const orderComplete = getDataLayer(state$.value).orderComplete;
        return { purchase, transaction, orderComplete };
      }),
      filter(({ purchase, orderComplete }) => purchase.isSupported || orderComplete.isSupported),
      switchMap(({ transaction: { id }, purchase, orderComplete }) => api.graphApi<DocumentsResponse>(documentDetailsQuery, { id }).pipe(
        tap(({ paymentTransaction }) => {
          const document = paymentTransaction?.document;
          if (document?.exists) {
            const data = toPurchaseInput(document, state$.value, id);
            if (purchase.isSupported)
              purchase.push(data);

            if (orderComplete.isSupported)
              orderComplete.push(data);
          }
        }),
        mergeMap(({ paymentTransaction }) => {
          if (paymentTransaction?.document?.exists)
            return api.graphApi(setTrackedMutation, { transactionId: id });

          return EMPTY;
        }),
        ignoreElements(),
      )),
    ),
  );
};

const toPurchaseInput = (
  {
    lines: { itemLines },
    totals,
    documentId,
    email,
  }: Document,
  {
    user,
    settings,
  }: AppState,
  transactionId: string,
) => ({
  itemLines,
  totals,
  pricesInclTax: user.pricesInclTax!,
  shopName: (settings as LoadedSettings).shopName || '',
  documentId,
  email,
  transactionId,
});

export default analyticsEpic;
