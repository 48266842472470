import type { Action } from 'redux';
import type { AppState } from 'behavior';
import type { Epic } from 'behavior/types';
import type { ModifiedLines } from 'behavior/basket/types';
import type { OrderTemplate, OrderTemplateLine } from './types';
import {
  orderTemplatesQuery,
  orderTemplateLinesQuery,
  addToBasketMutation,
  removeTemplatesMutation,
} from './queries';
import {
  ORDER_TEMPLATES_REQUESTED,
  ORDER_TEMPLATE_LINES_REQUESTED,
  ORDER_TEMPLATES_ADDING_REQUESTED,
  ORDER_TEMPLATES_REMOVAL_REQUESTED,
  orderTemplatesReceived,
  orderTemplateLinesReceived,
  orderTemplatesAdded,
  orderTemplatesRemoved,
  OrderTemplateAction,
} from './actions';
import { createApiCallEpic } from '../helpers';
import { combineEpics, ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { of, from } from 'rxjs';
import { mergeMap, map, takeUntil, switchMap, startWith } from 'rxjs/operators';
import { concatToIfEmpty } from 'utils/rxjs';
import { retryWithToast } from 'behavior/errorHandling';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { trackAddToBasket, getProductsTrackingDataFromLines, StandardEventSource } from 'behavior/analytics';
import { LoadedSettings } from 'behavior/settings';

type OrderTemplatesResponse = {
  orderTemplates: OrderTemplate[] | null;
};

const loadOrderTemplatesEpic = createApiCallEpic<OrderTemplateAction, OrderTemplatesResponse>(
  ORDER_TEMPLATES_REQUESTED,
  orderTemplatesQuery,
  orderTemplatesReceived,
);

type OrderTemplateLinesResponse = {
  orderTemplates: { lines: OrderTemplateLine[] }[] | null;
};

const loadOrderTemplateLinesEpic: Epic<OrderTemplateAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATE_LINES_REQUESTED),
      mergeMap(action => api.graphApi<OrderTemplateLinesResponse>(orderTemplateLinesQuery, {
        ...action.payload,
        loadCategories: state$.value.analytics?.isTrackingEnabled,
      }).pipe(
        map(({ orderTemplates }) =>
          orderTemplateLinesReceived(action.payload.id,
            orderTemplates &&
            orderTemplates[0] &&
            orderTemplates[0].lines.filter(l => l.product.exists || l.hasConfiguration),
        )),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      ),
    ),
  );
};

type AddToBasketResponse = {
  orderTemplates: {
    addToBasket: {
      templatesAmount: number;
      linesAmount: number;
      modifiedLines: ModifiedLines;
    } | null;
  } | null;
};

const addToBasketEpic: Epic<OrderTemplateAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  return action$.pipe(
    ofType(ORDER_TEMPLATES_ADDING_REQUESTED),
    switchMap(action => api.graphApi<AddToBasketResponse>(addToBasketMutation, { ...action.payload, requestModifiedLines: isTrackingEnabled(state$.value) }).pipe(
      mergeMap(({ orderTemplates }) => {
        if (!orderTemplates || !orderTemplates.addToBasket)
          return of(orderTemplatesAdded(0), basketChangeCompleted(0));

        const { templatesAmount, linesAmount, modifiedLines } = orderTemplates.addToBasket;
        const actions: Array<Action> = [orderTemplatesAdded(templatesAmount), basketChangeCompleted(linesAmount)];

        const addedProducts = modifiedLines ? getProductsTrackingDataFromLines(modifiedLines.list) : [];
        if (addedProducts && addedProducts.length) {
          actions.push(trackAddToBasket({ products: addedProducts, source: StandardEventSource.OrderTemplate }));
        }

        const backTo = state$.value.page.backTo;
        if (backTo) {
          actions.push(navigateTo(backTo.routeData, backTo.url));
        } else if ((state$.value.settings as LoadedSettings).basket.redirectOnAdd) {
          actions.push(navigateTo(routesBuilder.forBasket()));
        }

        return from(actions);
      }),
      retryWithToast(action$, logger),
      concatToIfEmpty(of(orderTemplatesAdded(0), basketChangeCompleted(0))),
      takeUntil(locationChanged$),
      startWith(basketChangeStarted()),
    )),
  );
};

const orderTemplatesRemoveEpic: Epic<OrderTemplateAction> = (action$, _state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATES_REMOVAL_REQUESTED),
    mergeMap(action => api.graphApi<unknown>(removeTemplatesMutation, action.payload).pipe(
      map(() => orderTemplatesRemoved(action.payload.ids)),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );
};

export default combineEpics(
  loadOrderTemplatesEpic,
  loadOrderTemplateLinesEpic,
  addToBasketEpic,
  orderTemplatesRemoveEpic,
);

function isTrackingEnabled(state: AppState) {
  return state.analytics && state.analytics.isTrackingEnabled;
}
