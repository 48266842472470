import type { Epic } from 'behavior/types';
import { AnalyticsAction, ANALYTICS_TRACK_SEARCH } from './actions';
import { skipIfPreview } from 'behavior/preview';
import {
  filter,
  first,
  delayWhen,
  tap,
  ignoreElements,
  pairwise,
  pluck,
  startWith,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getDataLayer } from './dataLayer';

const customEventsEpic: Epic<AnalyticsAction> = (action$, state$) => {
  const dataLayerReady$ = state$.pipe(
    first(({ analytics }) => analytics!.isDataLayerReady!),
  );

  return action$.pipe(
    skipIfPreview(state$),
    filter(_ => !!state$.value.analytics?.isTrackingEnabled),
    ofType(ANALYTICS_TRACK_SEARCH),
    delayWhen(_ => dataLayerReady$),
    pluck('payload'),
    startWith(null),
    pairwise(),
    tap(([previous, current]) => {
      if (current === null)
        return;

      const state = state$.value;
      const { trackSearch } = getDataLayer(state);

      if (!trackSearch.isSupported)
        return;

      const samePage = state.routing.location?.pathname === state.routing.previous?.location?.pathname;
      const samePayload = current.keywords === previous?.keywords;

      // Do not track the same value twice if we're staying on the same page.
      // This is the same search, just being refined with facets or paging or whatever else.
      if (samePage && samePayload)
        return;

      trackSearch.push(current);
    }),
    ignoreElements(),
  );
};

export default customEventsEpic;
