import { forwardRef, memo } from 'react';
import MobileFooterTemplate from './footer/MobileFooterTemplate';
import DesktopFooterTemplate from './footer/DesktopFooterTemplate';
import { Media } from 'components/responsive';
import { useSelector } from 'react-redux';

const Footer = forwardRef((_, ref) => {
  const hideFooterSection = useSelector(state => state.page && state.page.hideFooterSection);
  if (hideFooterSection)
    return null; 

  return (
    <footer id="footer" ref={ref}>
      <Media at="xs">
        <MobileFooterTemplate />
      </Media>
      <Media greaterThanOrEqual="sm">
        <DesktopFooterTemplate />
      </Media>
    </footer>
  );
});

export default memo(Footer);
