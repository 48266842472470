import styles from './Summary.module.scss';
import stylesPreOrder from 'components/objects/product/blocks/preOrderBanner/PreOrderBanner.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { ProductThumbnail } from 'components/primitives/product';
import PreOrderBanner from 'components/objects/product/blocks/preOrderBanner/PreOrderBanner';

const Thumbnail = ({ productTitle, imageUrl, productUrl, route, noImageUrl, isPreOrder }) => {
  if (productUrl) {
    return (
      <div className={stylesPreOrder.wrapper}>
        <WithProductClickTracking>
          {handleClick => (
            <Link url={productUrl} to={route} className={styles.productImg} onClick={handleClick}>
              <ProductThumbnail src={imageUrl} noImageSrc={noImageUrl} title={productTitle} />
            </Link>
          )}
        </WithProductClickTracking>
        {isPreOrder && <PreOrderBanner />}
      </div>
    );
  }

  return (
    <span className={styles.productImg}>
      <ProductThumbnail src={imageUrl} noImageSrc={noImageUrl} title={productTitle} />
    </span>
  );
};

Thumbnail.propTypes = {
  productTitle: PropTypes.string,
  imageUrl: PropTypes.string,
  productUrl: PropTypes.string,
  route: PropTypes.any.isRequired,
  noImageUrl: PropTypes.string,
  isPreOrder: PropTypes.bool,
};

export default connect(({ settings: { product } }) => ({
  noImageUrl: product ? product.noImage.small : null,
}))(Thumbnail);

