import type { Action } from 'redux';
import type { Epic } from 'behavior/types';
import type { User } from 'behavior/user';
import { ofType } from 'redux-observable';
import {
  filter,
  ignoreElements,
  tap,
} from 'rxjs/operators';
import { VIEWER_CHANGED } from 'behavior/events';
import { skipIfPreview } from 'behavior/preview';
import { AnalyticsAction, ANALYTICS_DATALAYER_READY } from './actions';
import { getDataLayer } from './dataLayer';

type ViewerChangedAction = Action<typeof VIEWER_CHANGED>;

const epic: Epic<AnalyticsAction | ViewerChangedAction> = (action$, state$, _) => action$.pipe(
  ofType(VIEWER_CHANGED, ANALYTICS_DATALAYER_READY),
  filter(_ => !!state$.value.analytics?.isDataLayerReady),
  skipIfPreview(state$),
  tap(_ => {
    const trackUser = getDataLayer(state$.value).trackUser;
    if (!trackUser.isSupported)
      return;

    const input = {
      userId: (state$.value.user as User).id,
    };

    trackUser.push(input);
  }),
  ignoreElements(),
);

export default epic;
