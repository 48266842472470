type Theme = {
  values?: Record<string, string>;
  version: string;
};

export const THEME_REQUESTED = 'THEME/REQUESTED' as const;
export const loadTheme = () => ({ type: THEME_REQUESTED });

export const THEME_LOADED = 'THEME/LOADED' as const;
export const themeLoaded = (theme: Theme | null) => ({ type: THEME_LOADED, payload: theme });

export type ThemeAction = ReturnType<
  | typeof loadTheme
  | typeof themeLoaded
>;
