import styles from '../MainNav.module.scss';
import { memo, useRef, useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { loadMainNavigationChildren } from 'behavior/navigation';
import { ComponentGroup } from 'behavior/navigation/constants';
import { Link } from 'components/primitives/links';
import { VerticalSliding } from 'components/primitives/transitions';
import ListItemStateIcon from '../ListItemStateIcon';
import MegaViewSubItems from './MegaViewSubItems';
import Spinner from 'components/primitives/spinner/Spinner';
import { shouldRenderNavigation, isRightArrowKeyPressed, isLeftArrowKeyPressed } from '../../helpers';
import { isModifierKeyPressed } from 'utils/helpers';
import { useOnPageChanged } from 'utils/hooks';
import { MainNavItemPropTypes, SublistParamsPropTypes } from '../PropTypes';
import { useNavItemEventHandlersData } from '../hooks';
import { setFocus, hoveredListClass, hoveredItemClass } from '../eventHandlers';

const MegaView = ({
  item: {
    id,
    shortTitle,
    title,
    cssClass,
    link,
    children: subItems,
  },
  isAccordion,
  ariaSubmenuText,
  sublistParams,
}) => {
  const timeoutId = useRef();
  const linkRef = useRef(null);
  const [isVisible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const shouldRenderSubItems = shouldRenderNavigation(subItems);
  const childrenCanBeLoaded = subItems && !shouldRenderSubItems;
  const {
    navItemEventHandlers, navItemLinkEventHandlers,
    shouldHandleTouchScreenInteraction, isMobileSafari, isNotMousePointer,
  } = useNavItemEventHandlersData(!!subItems);
  const shouldHandlePointerInteractionOrAria = !isAccordion && shouldRenderSubItems;

  const listItemEventHandlers = useMemo(
    () => isAccordion ? { onClick: navItemEventHandlers.onClick } : navItemEventHandlers,
    [isAccordion, navItemEventHandlers],
  );
  const linkEventHandlers = useMemo(
    () => isAccordion ? { onClick: setFocus, onContextMenu: setFocus } : navItemLinkEventHandlers,
    [isAccordion, navItemLinkEventHandlers],
  );

  const createItem = useCallback(subItem => (
    <MegaView
      key={subItem.id}
      item={subItem}
      isAccordion={isAccordion}
      ariaSubmenuText={ariaSubmenuText}
      sublistParams={sublistParams}
    />
  ), [isAccordion, ariaSubmenuText, sublistParams]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
      setVisible(shouldRenderSubItems);
    }
  }, [subItems]);

  useOnPageChanged(() => setVisible(false));

  const loadSubItems = childrenCanBeLoaded
    ? () => void (dispatch(loadMainNavigationChildren(ComponentGroup.CascadingMainMenu, id)), setLoading(true))
    : null;

  let handleClick = null, handleKeyDown = null;

  if (isAccordion) {
    handleClick = loadSubItems || (e => {
      if (e.target !== e.currentTarget)
        return;

      setVisible(!isVisible);
      linkRef.current.focus();
    });
  }

  if (isAccordion || childrenCanBeLoaded) {
    handleKeyDown = e => {
      const isCorrectTarget = e.target === linkRef.current;
      const isRightArrowKey = isRightArrowKeyPressed(e);

      if (!isCorrectTarget || isModifierKeyPressed(e) || (!isRightArrowKey && !isLeftArrowKeyPressed(e)))
        return;

      if (childrenCanBeLoaded) {
        if (isRightArrowKey) {
          const listItem = e.currentTarget.parentElement;
          loadSubItems();
          listItem.classList.add(hoveredItemClass);
          listItem.parentElement.classList.add(hoveredListClass);
        }
        return;
      }

      setVisible(isRightArrowKey);
    };
  }

  const subItemsBlock = shouldRenderSubItems && (
    <MegaViewSubItems
      subItems={subItems}
      id={id}
      createItem={createItem}
      isAccordion={isAccordion}
      expanded={isAccordion ? isVisible : null}
      title={shortTitle || title}
      ariaSubmenuText={ariaSubmenuText}
      sublistParams={sublistParams}
    />
  );

  const hypContainerStyles = `${styles.hypContainer} ${subItems ? styles.hasSubItems : ''}`;

  return (
    <li
      className={`${styles.navItemSub} ${cssClass || ''}`}
      {...listItemEventHandlers}
      aria-keyshortcuts={subItems ? 'ArrowRight ArrowLeft' : null}
      data-should-handle-aria={shouldHandlePointerInteractionOrAria ? 'true' : null}
      aria-controls={shouldRenderSubItems ? id : null}
      aria-live={subItems ? 'polite' : null}
      aria-atomic={subItems ? 'true' : null}
    >
      <div
        // Element should be focusable for proper event handling in Mobile Safari, Legacy MS Edge and IE11 when using laptop with touch screen.
        tabIndex="-1"
        className={hypContainerStyles}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        // onMouseOver event is supported by onKeyDown instead of onFocus. The links inside will be actual targets.
        // onMouseOver is not reliable when using touch screen on laptop - it is fired twice for touch position and last known mouse cursor position.
        onMouseOver={shouldHandleTouchScreenInteraction || isAccordion ? null : isNotMousePointer ? loadSubItems : () => timeoutId.current = setTimeout(loadSubItems, 200)}
        onMouseLeave={isAccordion || isNotMousePointer ? null : () => clearTimeout(timeoutId.current)}
        onPointerUp={shouldHandleTouchScreenInteraction && !isAccordion ? loadSubItems : null}
        onTouchCancel={!isAccordion && isMobileSafari && isNotMousePointer ? loadSubItems : null}
        onContextMenu={shouldHandleTouchScreenInteraction && !isAccordion ? loadSubItems : null}
        role="presentation"
      >
        <Link
          {...link}
          className={styles.hypNestedLvl}
          ref={linkRef}
          {...linkEventHandlers}
          aria-haspopup={shouldRenderSubItems ? 'true' : null}
        >
          <span>{shortTitle || title}</span>
        </Link>
        {isAccordion && subItems && !loading && <ListItemStateIcon active={isVisible} className={styles.listItemIcon} />}
        {loading && <Spinner className={styles.spinner} />}
      </div>
      {subItemsBlock && (
        isAccordion
          ? <VerticalSliding expanded={isVisible}>{subItemsBlock}</VerticalSliding>
          : subItemsBlock
      )}
    </li>
  );
};

MegaView.propTypes = {
  item: MainNavItemPropTypes,
  isAccordion: PropTypes.bool,
  ariaSubmenuText: PropTypes.string,
  sublistParams: SublistParamsPropTypes,
};

export default memo(MegaView);