import type { User, Viewer, Abilities, Token } from './types';
import type { RouteData, BackTo } from 'routes';
import type { StateObservable } from 'redux-observable';
import type { Action } from 'redux';
import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import type { Link } from 'behavior/navigation';
import { authenticated, loginFailed } from './actions';
import { viewerChanged, navigateTo } from 'behavior/events';
import { tap } from 'rxjs/operators';
import { routesBuilder } from 'routes';
import { Observer, Observable, from, of, EMPTY, merge } from 'rxjs';
import { getBackToFromUrl } from 'behavior/pages/helpers';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { arrayToObject } from 'utils/helpers';
import { loadTheme } from 'behavior/theme/actions';

type ExpirationsObserver = Observer<Date | null>;

export function createMapLoginResult(state$: StateObservable<AppState>, { api, scope }: StoreDependencies, expirationsObserver?: ExpirationsObserver) {
  return function (email: string, loginResult: { token: Token | null }, viewerResult: Viewer): Observable<Action> {
    if (loginResult.token) {
      expirationsObserver && expirationsObserver.next(loginResult.token.expiration);
      api.setAuthToken(loginResult.token.value);

      const data = createUserData(viewerResult, true);
      data.email = email;

      const redirectTo = getBackToFromUrl(scope);
      if (redirectTo) {
        return from([
          authenticated(data),
          viewerChanged(),
          loadTheme(),
          navigateTo(redirectTo.routeData, redirectTo.url),
        ]);
      }

      return merge(from([authenticated(data), viewerChanged(), loadTheme()]), handleNavigateToAction(viewerResult, state$.value.page.backTo));
    }

    return of(unsetLoadingIndicator(), loginFailed());
  };
}

export function createUserData(viewer: Viewer, isAuthenticated: boolean): User {
  const userData = { ...viewer, isAuthenticated } as User;

  if (viewer.abilities)
    userData.abilities = convertAbilities(viewer.abilities);

  return userData;
}

export function handleToken(api: StoreDependencies['api'], expirationsObserver?: ExpirationsObserver, broadcast = true) {
  return tap((res: { viewer: Viewer }) => {
    const token = res.viewer.token;
    if (token !== undefined) {
      expirationsObserver && expirationsObserver.next(token.expiration);
      api.setAuthToken(token.value, broadcast);
      delete res.viewer.token;
    }
  });
}

export function convertAbilities(abilities: Required<Viewer>['abilities']): Abilities {
  return arrayToObject(abilities, ability => ability.key, ability => ability.state);
}

function handleNavigateToAction(viewerResult: Viewer, backTo: BackTo | undefined): Observable<Action | never> {
  const customerBrandPage = extractLinkData(viewerResult.customerBrandPage);
  if (customerBrandPage?.routeData) {
    return of(navigateTo(customerBrandPage.routeData, customerBrandPage.url));
  }

  if (customerBrandPage) {
    window.location.href = customerBrandPage.url;
    return EMPTY;
  }

  if (backTo) {
    return of(navigateTo(backTo.routeData, backTo.url));
  }

  return of(navigateTo(routesBuilder.forHome()));
}

function extractLinkData(link: Link | null | undefined): { url: string; routeData?: RouteData } | null {
  if (!link || link.url === null)
    return null;

  if (link.to !== null) {
    return { url: link.url, routeData: { routeName: link.to.routeName, params: link.to.params } };
  }

  return { url: link.url };
}
