import type { MainMenuItem, MainMenuItemWithParent } from 'behavior/navigation/types';
import type { State } from 'behavior/navigation/pageReducer';
import { useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useRoutes } from 'components/primitives/route';
import { routesBuilder } from 'routes';
import { findNavigationPath, getMainNavigation } from './helpers';
import { loadPageNavigationItems } from 'behavior/navigation/actions';

const homeRoute = routesBuilder.forHome();

const useNavigationPath = (useParentPath: boolean, removeLastSegment = true): MainMenuItem[] | MainMenuItemWithParent[] | null => {
  const dispatch = useDispatch();
  const [homePath] = useRoutes([homeRoute]);
  const { items, url, navigation } = useSelector(state => ({
    items: (state.page as State).navigationItems,
    url: state.routing.location?.pathname,
    navigation: getMainNavigation(state),
  }), shallowEqual);

  return useMemo(() => {
    if (!url || !homePath)
      return null;

    const preparedUrl = removeLastSegment 
      ? trimLastSegment(url)
      : url;

    const processFoundPath = (items: MainMenuItem[] | MainMenuItemWithParent[]) => {
      // Condition below works when PDP is not bound to main navigation item or bound to one which doesn`t mutate page`s URL.
      if (useParentPath && preparedUrl === homePath)
        return items.length > 1
          ? items.slice(0, -1)
          : null;

      return items;
    };

    if (items)
      return processFoundPath(items);

    if (!navigation)
      return null;

    const useParentUrl = useParentPath && preparedUrl !== homePath;
      const urlForSearch = useParentUrl ? preparedUrl : url;

    const navigationItems = findNavigationPath(urlForSearch, navigation);
    if (navigationItems)
      return processFoundPath(navigationItems);

    setTimeout(() => dispatch(loadPageNavigationItems(urlForSearch)));

    return null;
  }, [items, url, navigation, homePath]);
};

const trimLastSegment = (url: string) => url.slice(0, url.lastIndexOf('/') + 1);

export default useNavigationPath;
