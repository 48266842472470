import type { Epic } from 'behavior/types';
import { mergeMap, exhaustMap, takeUntil, pluck, map } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  ResentlyPurchasedProductsAction,
  receiveRecentlyPurchasedProducts,
  REQUEST_RECENTLY_PURCHASED_PRODUCTS,
} from './actions';
import { resentlyPurchasedProductsQuery, loadCalculatedFieldsQuery } from './queries';
import { VIEWER_CHANGED } from 'behavior/events';
import { ResentlyPurchasedProducts, CalculatedResentlyPurchasedProducts } from './types';

const epic: Epic<ResentlyPurchasedProductsAction> = (action$, _state$, { api }) => {
  const reset$ = action$.pipe(
    ofType(
      VIEWER_CHANGED,
    ),
  );

  const requestProducts$ = action$.pipe(
    ofType(REQUEST_RECENTLY_PURCHASED_PRODUCTS),
    exhaustMap(() =>  api.graphApi<ResentlyPurchasedProductsResponse>(resentlyPurchasedProductsQuery).pipe(
        pluck('catalog', 'resentlyPurchasedProducts'),
        mergeMap(result => {
          if (!result)
            return of(receiveRecentlyPurchasedProducts());

          return merge(
            of(receiveRecentlyPurchasedProducts(result)),
            api.graphApi<CalculatedResentlyPurchasedProductsResponse>(loadCalculatedFieldsQuery).pipe(
              pluck('catalog', 'resentlyPurchasedProducts'),
              map(result => receiveRecentlyPurchasedProducts(result)),
            ),
          );
        }),
        takeUntil(reset$),
      ),
    ),
  );

  return requestProducts$;
};

export default epic;

type ResentlyPurchasedProductsResponse = {
  catalog: {
    resentlyPurchasedProducts: ResentlyPurchasedProducts;
  };
};

type CalculatedResentlyPurchasedProductsResponse = {
  catalog: {
    resentlyPurchasedProducts: CalculatedResentlyPurchasedProducts;
  };
};