import styles from './PreOrderBanner.module.scss';
import { Placeholder } from 'components/primitives/placeholders';
import { useSimpleTexts } from 'components/sanaText';
import { joinClasses } from 'utils/helpers';
import PropTypes from 'prop-types';

const PreOrderBanner = ({ alignCenter, small }) => {
  const { texts: [message], loaded } = useSimpleTexts(['PreOrderBannerBlock_PreOrderLabel']);

  if (!loaded)
    return <Placeholder />;

  return (
    <div className={joinClasses(
      styles.container,
      alignCenter && styles.center,
    )}
    >
      <div className={joinClasses(
        styles.label,
        small && styles.small,
      )}
      >
        {message}
      </div>
    </div>
  );
};

PreOrderBanner.propTypes = {
  alignCenter: PropTypes.bool,
  small: PropTypes.bool,
};

export default PreOrderBanner;