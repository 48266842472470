import { mergeMap } from 'rxjs/operators';
import { navigateTo } from 'behavior/events';
import { Handler, Page } from '../types';
import { of } from 'rxjs';
import { RouteName } from 'routes';
import { restoreBasketMutation } from './queries';

const handler: Handler<AbandonedBasketRouteData, Page> = (routeData, _state$, { api }) => {
  const { params: { id } } = routeData;
  if (!id || id.length !== 36)
    return of(null);

  return api.graphApi(restoreBasketMutation, { id }).pipe(
    mergeMap(({ basket: { restoreAbandonedBasket } }) => {
      if (!restoreAbandonedBasket) {
        return of(null);
      }

      return of({
        action$: of(navigateTo({
          routeName: RouteName.BasketPage as const,
        })),
      });
    }),
  );
};

export default handler;

type AbandonedBasketRouteData = {
  routeName: RouteName.RestoreAbandonedBasket;
  params: {
    id?: string;
  };
};
