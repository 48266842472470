import type { RouteName } from 'routes';
import type { Handler } from '../types';
import { of } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initComponent } from '../helpers';
import { loadFaqPageQuery } from './queries';

type FaqItem = {
  id: string;
  title: string;
  description: string | null;
  children: FaqItem[] | null;
};

type FaqPage = {
  component: PageComponentNames.Faq;
  metaTitle: string | null;
  metaDescription: string | null;
  heading: string;
  headerText: string | null;
  footerText: string | null;
  showLeftMenu: boolean;
  faqItems: FaqItem[];
};

type LoadFaqPageResponse = {
  pages: {
    faq: FaqPage;
  };
};

type FaqPageRouteData = {
  routeName: RouteName.FaqPage;
  params?: { id: string };
};

const handler: Handler<FaqPageRouteData, FaqPage> = (routeData, _state$, { api }) => {
  if (!routeData.params?.id)
    return of(null);

  const id = routeData.params.id;

  return api.graphApi<LoadFaqPageResponse>(loadFaqPageQuery, { id }).pipe(
    pluck('pages', 'faq'),
    initComponent(PageComponentNames.Faq),
  );
};

export default handler;
