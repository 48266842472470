import type { Epic } from 'behavior/types';
import { USER_EDIT_PROFILE, EditProfileAction, processedEditProfile, AddressValidationResult } from './actions';
import { editProfileMutation } from './queries';
import { switchMap, mergeMap, startWith } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormName } from 'behavior/pages';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { createUserData } from 'behavior/user/helpers';
import { userProfileUpdated, Viewer } from 'behavior/user';
import { ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { RouteName } from 'routes';

const editProfileEpic: Epic<EditProfileAction> = (action$, state$, { api, logger }) => {
  const errorAction$ = of(unlockForm(FormName.EditProfile), unsetLoadingIndicator());

  return action$.pipe(
    ofType(USER_EDIT_PROFILE),
    switchMap(action => api.graphApi<EditProfileResponse>(editProfileMutation, { input: action.payload }).pipe(
      mergeMap(({ viewer, profile }) => {
        if (!profile.editProfile.isEdited)
          return of(unlockForm(FormName.EditProfile), unsetLoadingIndicator(), processedEditProfile(profile.editProfile.addressValidationResult));

        const user = createUserData(viewer, true);
        const backTo = state$.value.page.backTo;

        return of(
          userProfileUpdated(user),
          backTo?.routeData.routeName === RouteName.Checkout
            ? navigateTo(backTo.routeData, backTo.url)
            : navigateTo(routesBuilder.forMyAccount()),
        );
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], errorAction$),
      retryWithToast(action$, logger, _ => errorAction$),
      startWith(setLoadingIndicator()),
    )),
  );
};

export default editProfileEpic;

type EditProfileType = {
  isEdited: boolean;
  addressValidationResult: AddressValidationResult;
};

type EditProfileResponse = {
  viewer: Viewer;
  profile: {
    editProfile: EditProfileType;
  };
};
