import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  tap,
  pluck,
  switchMap,
  map,
  ignoreElements,
  withLatestFrom,
} from 'rxjs/operators';

import {
  PRODUCT_COMPARISON_ADD_PRODUCT,
  PRODUCT_COMPARISON_REMOVE_PRODUCT,
  PRODUCT_COMPARISON_REQUEST_INITIALIZATION,
  PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS,
  initializeProductComparison,
  ProductComparisonAction,
  productRemovedFromComparison,
} from './actions';

import {
  addProductToStorage,
  removeProductFromStorage,
  getProductsFromStorage,
  removeAllProductsFromStorage,
} from './util';

import { productsToCompareRequest } from './queries';
import type { Epic } from 'behavior/types';
import type { Product } from './types';
import type { Api } from 'utils/api';
import type { LoadedSettings } from 'behavior/settings';

type AnalyticsSettings = {
  isTrackingEnabled: boolean;
} | null;

const productComparisonEpic: Epic<ProductComparisonAction> = (action$, state$, { api, localStorage }) => {
  const requestProductsToCompareAction$ = action$.pipe(
    ofType(PRODUCT_COMPARISON_REQUEST_INITIALIZATION),
    withLatestFrom(state$),
    map(([_, { settings, analytics }]): [string[] | null, AnalyticsSettings] => {
      const ids = settings.loaded ? getProductsFromStorage(localStorage, settings.product) : null;
      return [ids, analytics];
    }),
    switchMap(
      ([ids, analytics]) => ids && ids.length > 0
        ? loadProducts(api, ids, analytics)
        : of([]),
    ),
    map(initializeProductComparison),
  );

  const addProductToComparisonAction$ = action$.pipe(
    ofType(PRODUCT_COMPARISON_ADD_PRODUCT),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    tap(([id, { settings }]) => settings.loaded && addProductToStorage(localStorage, settings.product, id)),
    ignoreElements(),
  );

  const removeProductFromComparisonAction$ = action$.pipe(
    ofType(PRODUCT_COMPARISON_REMOVE_PRODUCT),
    pluck('payload', 'id'),
    withLatestFrom(state$),
    tap(([id, { settings }]) => settings.loaded && removeProductFromStorage(localStorage, settings.product, id)),
    map(([id, { settings }]) => {
      return settings.loaded ? productRemovedFromComparison(id, (settings as LoadedSettings).product.productComparison.perfionTabsToCompare):
              productRemovedFromComparison(id, []);
    }),
  );

  const removeAllProductsFromComparisonAction$ = action$.pipe(
    ofType(PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS),
    tap(_ => removeAllProductsFromStorage(localStorage)),
    ignoreElements(),
  );

  return merge(
    requestProductsToCompareAction$,
    addProductToComparisonAction$,
    removeProductFromComparisonAction$,
    removeAllProductsFromComparisonAction$,
  );
};

export default productComparisonEpic;

function sort(products: Product[] | null, ids: string[]) {
  if (!products || !products.length)
    return [];

  return ids
    .map(id => products.find(p => p.id.toLocaleUpperCase() === id.toLocaleUpperCase()))
    .filter((product): product is Product => !!product);
}

function loadProducts(api: Api, ids: string[], analytics: AnalyticsSettings) {
  const isTrackingEnabled = analytics && analytics.isTrackingEnabled;

  const variables = {
    options: {
      ids,
      page: {
        size: ids.length,
      },
    },
    loadUom: isTrackingEnabled,
    loadCategories: isTrackingEnabled,
  };

  return api.graphApi(productsToCompareRequest, variables).pipe(
    pluck('catalog', 'products', 'products'),
    map(products => sort(products, ids)),
  );
}
