import loadable from '@loadable/component';

const CxmlPunchoutError = loadable(() => import(/*webpackChunkName:"user"*/'./cxmlPunchoutError'));
const OciPunchoutError = loadable(() => import(/*webpackChunkName:"user"*/'./ociPunchoutError'));

/* eslint-disable react/no-multi-comp */
export function cxmlPunchoutErrorRenderer(_page) {
  return <CxmlPunchoutError />;
}

export function ociPunchoutErrorRenderer(_page) {
  return <OciPunchoutError />;
}
