import { useMemo, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMatchMediaContext } from 'components/responsive';
import { isTouchScreenPresent } from 'utils/detections';
import { useIsMouse } from 'components/detection';
import {
  addHoverState,
  removeItemHoverState,
  toggleHoverState,
  handlePointerMove,
  handlePointerLeave,
  addItemHoverState,
  disableLinkInteraction,
  handleLinkClickOnCapture,
  handleLinkClick,
  setFocus,
} from './eventHandlers';
import { shouldRenderNavigation } from '../helpers';
import { NavigationGroupCode } from 'behavior/navigation/constants';
import NavMenuContext from './NavMenuContext';

let timerId;

export const useNavItemEventHandlersData = (shouldRenderSubItems, withLinkHandlers = true) => {
  const { isSafari, isTouchDevice, isIE, isTouchScreen } = useMatchMediaContext();
  const hasTouchScreen = isTouchScreenPresent(isTouchScreen, isIE);
  const isMobileSafari = isSafari && isTouchDevice;
  const isMouse = useIsMouse();

  const isNotMousePointer = isMouse === false;
  const shouldHandleTouchInteraction = shouldRenderSubItems && (isTouchDevice || hasTouchScreen) && isNotMousePointer;
  const shouldHandleTouchScreenInteraction = hasTouchScreen && isNotMousePointer;
  const shouldHandleLinkTouchInteraction = shouldHandleTouchInteraction && !isMobileSafari;
  const shouldHandleSafariTouchInteraction = shouldRenderSubItems && isMobileSafari && isNotMousePointer;

  const addHoverStateDebounced = useCallback(e => {
    const event = { ...e };
    timerId = setTimeout(() => addHoverState(event), 300);
  }, []);

  const removeItemHoverStateDebounced = useCallback(e => {
    clearTimeout(timerId);
    const event = { ...e };
    removeItemHoverState(event);
  }, []);

  return useMemo(
    () => {
      const result = {
        shouldHandleTouchScreenInteraction,
        isMobileSafari,
        isNotMousePointer,
        navItemEventHandlers: {
          // onMouseOver event is supported by onKeyDown instead of onFocus. The links inside will be actual targets.
          // onMouseOver, onMouseLeave are not reliable when using touch screen on laptop - they are fired twice for touch position and last known mouse cursor position.
          onMouseOver: shouldHandleTouchScreenInteraction ? null : addHoverState,
          onPointerMove: shouldHandleTouchScreenInteraction ? handlePointerMove : null,
          onPointerLeave: shouldHandleTouchScreenInteraction ? handlePointerLeave : null,
          onPointerUp: shouldHandleTouchScreenInteraction ? addHoverState : null,
          onTouchCancel: shouldHandleSafariTouchInteraction ? addHoverState : null,
          onMouseLeave: shouldHandleTouchScreenInteraction ? null : removeItemHoverState,
          onBlur: removeItemHoverState,
          onKeyDown: toggleHoverState,
          onClick: shouldHandleTouchInteraction ? addItemHoverState : null,
          onContextMenu: shouldHandleTouchScreenInteraction ? addHoverState : null,
        },
        navItemEventHandlersDebounced: {
          onMouseOver: shouldHandleTouchScreenInteraction ? null : addHoverStateDebounced,
          onPointerUp: shouldHandleTouchScreenInteraction ? addHoverStateDebounced : null,
          onTouchCancel: shouldHandleSafariTouchInteraction ? addHoverStateDebounced : null,
          onMouseLeave: shouldHandleTouchScreenInteraction ? null : removeItemHoverStateDebounced,
          onBlur: removeItemHoverStateDebounced,
          onContextMenu: shouldHandleTouchScreenInteraction ? addHoverStateDebounced : null,
        },
      };

      if (withLinkHandlers) {
        result.navItemLinkEventHandlers = {
          onTouchCancel: shouldHandleSafariTouchInteraction ? disableLinkInteraction : null,
          // onMouseOver is used only in Mobile Safari for correcting menu touch interaction behavior. Link itself has default keyboard related behavior.
          onMouseOver: shouldHandleSafariTouchInteraction ? disableLinkInteraction : null,
          onContextMenu: shouldHandleLinkTouchInteraction
            ? disableLinkInteraction
            : shouldHandleTouchScreenInteraction ? setFocus : null,
          onClick: shouldHandleTouchInteraction ? handleLinkClick : null,
          onClickCapture: shouldHandleTouchInteraction ? handleLinkClickOnCapture : null,
        };
      }

      return result;
    },
    [
      shouldRenderSubItems,
      hasTouchScreen,
      shouldHandleTouchScreenInteraction,
      isMouse,
      shouldHandleSafariTouchInteraction,
      shouldHandleTouchInteraction,
      shouldHandleLinkTouchInteraction,
      withLinkHandlers,
    ],
  );
};

export function useNavMenuContext() {
  return useContext(NavMenuContext);
}

export function useIsMainNavItemsLoaded() {
  const { componentGroup } = useNavMenuContext();

  return useSelector(s => shouldRenderNavigation(s.navigation[componentGroup]?.[NavigationGroupCode.Main]));
}
