export const USER_EDIT_PROFILE = 'USER/EDIT_PROFILE' as const;
export const editProfile = (customerData: { id: string } & Record<string, any>, ignoreAddressValidation: boolean) => ({
  type: USER_EDIT_PROFILE,
  payload: { customerData, ignoreAddressValidation },
});

type SuggestedAddress = {
  address: string;
  address2: string | null;
  city: string;
  countryId: string;
  state: string;
  zipCode: string;
};

export type AddressValidationResult = {
  isValidAddress: boolean;
  suggestedAddresses?: SuggestedAddress[] | null;
  addressStatus: string;
};

export const USER_EDIT_PROCESSED = 'USER/EDIT_PROCESSED';
export const processedEditProfile = (addressValidationResult : AddressValidationResult) => ({
  type: USER_EDIT_PROCESSED,
  payload: { addressValidationResult },
});

export type EditProfileAction = ReturnType<typeof editProfile | typeof processedEditProfile>;
