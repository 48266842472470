import type {
  CustomEventSource,
  ProductLine,
  VariantProductLine,
  CheckoutInput,
  CheckoutOptionInput,
  ProductClickInput,
  ProductDetailsViewInput,
  ProductListViewInput,
  ProductsAddToBasketInput,
  ProductsRemoveFromBasketInput,
  PurchaseInput,
  ProductInputData,
  PageViewInput,
  TrackUserInput,
} from 'behavior/analytics/types';

import type { StandardEventSource } from 'behavior/analytics/constants';

import { createUrl } from 'behavior/routing/helpers';

import {
  addPropIfNotNull,
  eventSourceToList,
  getProductCategories,
  getProductInfo,
  roundDecimal,
} from './common';

import type { AppState } from 'behavior';

export const createProductClickPayload = ({ product, source }: ProductClickInput) => ({
  event: 'productClick',
  ecommerce: {
    click: {
      actionField: {
        list: eventSourceToList(source).name,
      },
      products: [toProductOutput(product)],
    },
  },
});

export const createProductDetailsViewPayload = ({ product }: ProductDetailsViewInput) => ({
  event: 'detail',
  ecommerce: {
    detail: {
      products: [toProductOutput(product)],
    },
  },
});

export const createProductListViewPayload = ({ products, source }: ProductListViewInput, state: AppState) => ({
  event: 'impression',
  ecommerce: {
    currencyCode: state.user.currencyId || '',
    impressions: {
      products: products.map(product => toProductOutput(product, source)),
    },
  },
});

export const createProductsAddToBasketPayload = ({ products }: ProductsAddToBasketInput, state: AppState) => ({
  event: 'addToCart',
  ecommerce: {
    currencyCode: state.user.currencyId || '',
    add: {
      products: products.map(product => toProductOutput(product)),
    },
  },
});

export const createProductsRemoveFromBasketPayload = ({ products }: ProductsRemoveFromBasketInput) => ({
  event: 'removeFromCart',
  ecommerce: {
    remove: {
      products: products.map(product => toProductOutput(product)),
    },
  },
});

export const createCheckoutPayload = ({ step, products }: CheckoutInput, state: AppState) => ({
  event: 'checkout',
  ecommerce: {
    checkout: {
      actionField: {
        step,
      },
      products: products.map(product => toProductOutput(product)),
    },
  },
  customerType: state.user.customerType,
});

export const createCheckoutOptionPayload = (actionField: CheckoutOptionInput) => ({
  event: 'checkoutOption',
  ecommerce: {
    checkout_option: {
      actionField,
    },
  },
});

export const createPurchasePayload = (input: PurchaseInput, state: AppState) => {
  const {
    itemLines,
    totals,
    pricesInclTax,
    documentId,
  } = input;

  const products = extractProducts(itemLines);
  const revenue = pricesInclTax ? totals?.totalPrice : totals?.totalExcludingTax;

  const actionField = {
    id: documentId,
    affiliation: input.shopName,
    revenue: revenue ?? 0,
    shipping: totals?.shippingCost ?? 0,
    tax: totals?.taxAmount ?? 0,
    coupon: undefined as string | undefined,
  };

  if (totals?.promotion)
    actionField.coupon = totals.promotion.title;

  return {
    event: 'purchase',
    ecommerce: {
      currencyCode: state.user.currencyId || '',
      purchase: {
        actionField,
        products,
      },
    },
    customerType: state.user.customerType,
    documentId,
  };
};

const isVariantLine = (line: ProductLine): line is VariantProductLine =>
  'sublines' in line;

const extractProducts = (itemLines: Array<ProductLine>) => {
  const products: ReturnType<typeof toProductOutput>[] = [];

  itemLines.forEach(itemLine => {
    if (isVariantLine(itemLine)) {
      itemLine.sublines.forEach(subline => {
        const product = toProductOutput({
          id: itemLine.product.id,
          title: itemLine.title,
          variant: subline.title,
          uom: subline.uom,
          price: subline.price,
          quantity: subline.quantity,
          categoriesPaths: itemLine.product.categoriesPaths,
        });
        products.push(product);
      });
    } else {
      const product = toProductOutput({
        id: itemLine.product.id,
        title: itemLine.title,
        uom: itemLine.uom,
        price: itemLine.price,
        quantity: itemLine.quantity,
        categoriesPaths: itemLine.product.categoriesPaths,
      });
      products.push(product);
    }
  });

  return products;
};

export const createPageViewPayload = ({ origin, pageTitle }: PageViewInput, state: AppState) => ({
  event: 'pageView',
  pageUrl: origin + createUrl(state.routing.location!),
  pageUrlFragment: state.routing.location!.hash,
  oldPageUrl: state.routing.previous ? origin + createUrl(state.routing.previous.location) : '',
  oldPageUrlFragment: state.routing.previous?.location.hash || '',
  pageTitle,
});

export const createUserTrackingPayload = ({ userId }: TrackUserInput) => ({ userId });

export const toProductOutput = (
  productInput: ProductInputData,
  source?: StandardEventSource | CustomEventSource,
) => {
  const data: {
    id: string;
    name: string;
    variant?: string;
    price?: number | null;
    quantity?: number;
    category?: null | string;
    list?: string;
  } = getProductInfo(productInput);

  const { productGroup, categoriesPaths, variant, price, quantity } = productInput;

  !!source && addPropIfNotNull(data, 'list', eventSourceToList(source).name);
  addPropIfNotNull(data, 'category', convertToOneCategory(getProductCategories(categoriesPaths)));

  if (productGroup)
    return data;

  addPropIfNotNull(data, 'variant', variant);
  addPropIfNotNull(data, 'price', price);
  addPropIfNotNull(data, 'quantity', roundDecimal(quantity));

  return data;
};

const convertToOneCategory = (categories: string[]): string | null => {
  if (!categories.length)
    return null;

  return categories.map(c => c.replace('/', '|')).join('/');
};
