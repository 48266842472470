import styles from './Gallery.module.scss';
import { thumbnailSize, thumbnailSmallSize } from '../thumbnails/Thumbnails.module.scss';
import { memo, useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Swipe from 'react-easy-swipe';
import Spinner from 'components/primitives/spinner/Spinner';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import GalleryImage from './GalleryImage';
import GalleryVideo from './GalleryVideo';
import { ProductMediaType } from 'behavior/pages/product';

const Gallery = ({ index = 0, items, noImage, onChange, showLargeGallery, onPlayerChange, preventZoom, isSmall }) => {
  const mediaRef = useRef(null);
  const fadeTimer = useRef(0);
  const [activeIndex, setActiveIndex] = useState(index);

  const zoomState = useState(false);
  const [productNoImageText] = useSanaTexts(['Product_NoImage'], makeSimpleText).texts;

  const activeItem = items[activeIndex];

  const itemProps = getItemProps(activeItem, noImage, productNoImageText);

  const goTo = useCallback(newIndex => {
    if (newIndex === activeIndex || newIndex < 0 || newIndex >= items.length)
      return;

    mediaRef.current.parentElement.classList.remove(styles.fadeIn);

    clearTimeout(fadeTimer.current);

    fadeTimer.current = setTimeout(() => {
      onChange && onChange(newIndex);
      setActiveIndex(newIndex);
    }, styles.fadeTime);
  }, [activeIndex]);

  const swipeNext = () => {
    if (!zoomState[0])
      goTo(activeIndex + 1);
  };

  const swipePrev = () => {
    if (!zoomState[0])
      goTo(activeIndex - 1);
  };

  useEffect(() => {
    goTo(index);
  }, [index]);

  const onLoad = useCallback(() => {
    mediaRef.current.parentElement.classList.add(styles.fadeIn);
  });

  const mediaItem = activeItem && activeItem.type === ProductMediaType.Video
    ? (
      <GalleryVideo
        videoData={itemProps}
        ref={mediaRef}
        onLoad={onLoad}
        onPlayerChange={onPlayerChange}
        noImage={noImage}
      />
    )
    : (
      <GalleryImage
        imgProps={itemProps}
        index={activeIndex}
        showLargeGallery={showLargeGallery}
        zoomState={zoomState}
        item={activeItem}
        onLoad={onLoad}
        ref={mediaRef}
        preventZoom={preventZoom}
      />
    );

  return (
    <Swipe className={`${styles.feature} ${isSmall ? styles.featureSmall : ''}`}
      allowMouseEvents
      onSwipeLeft={swipeNext}
      onSwipeRight={swipePrev}
      tolerance={isSmall ? thumbnailSize / 2 : thumbnailSmallSize / 2}
    >
      <div className={styles.spinner}><Spinner /></div>
      {mediaItem}
    </Swipe>
  );
};

Gallery.propTypes = {
  items: PropTypes.array.isRequired,
  index: PropTypes.number,
  noImage: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showLargeGallery: PropTypes.func,
  onPlayerChange: PropTypes.func.isRequired,
  preventZoom: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default memo(Gallery);

function getItemProps(item, noImage, productNoImageText) {
  if (!item) {
    return {
      src: noImage,
      title: productNoImageText,
      className: styles.noImage,
    };
  }

  if (item.type === ProductMediaType.Video)
    return item.videoData;

  return {
    src: item.medium || item.small,
    title: item.title,
    alt: item.alt,
  };
}
