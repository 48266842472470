import { RouteName } from 'routes';
import {
  closedStoreMiddleware,
  authorizeMiddleware,
  createAbilityMiddleware,
  applyMiddleware,
} from './middleware';
import { AbilityTo } from 'behavior/user/constants';

import { createSystemPageHandler, homeHandler, fallbackHandler } from './system';
import { contentHandler } from './contentPage';
import { representHandler } from './representation';
import { newsHandler } from './news';
import { subAccountHandler, subAccountsHandler } from './subAccounts';
import { newsDetailHandler } from './news/details';
import { visualDesignerHandler } from './visualDesigner';
import { faqHandler } from './faq';
import { productDetailsHandler } from './product';
import { productListHandler } from './productList';
import { productGroupPageHandler } from './productGroup';
import { basketHandler } from './basket';
import { websiteRedirectHandler } from './websiteRedirect';
import { previewHandler } from './preview';
import { lastViewedPageHandler } from './productList/lastViewed';
import { orderTemplatesHandler } from './orderTemplates';
import { searchHandler } from './productList/search';
import { productsWithCategoryHandler } from './productList/withCategory';
import {
  ordersHandler,
  quotesHandler,
  invoicesHandler,
  returnOrdersHandler,
  creditNotesHandler,
  returnReceiptsHandler,
  shipmentsHandler,
} from './documents';
import { resetPasswordHandler } from './resetPassword';
import { handler as registrationHandler } from './registration';
import { handler as createProspectHandler } from './createProspect';
import { handler as loginPageHandler } from './login';
import { handler as forgotPasswordPageHandler } from './forgotPassword';
import { documentHandler } from './document';
import { handler as productComparisonPageHandler } from './productComparison';
import { salesAgreementHandler, salesAgreementsHandler } from './salesAgreements';
import {
  checkoutHandler,
  orderSubmitHandler,
  orderFailedHandler,
  orderCancelledHandler,
  paymentErrorHandler,
} from './checkout';
import { handler as editProfileHandler } from './editProfile';
import { handler as orderAuthorizationsHandler } from './orderAuthorizations';
import { handler as invoicePaymentHandler, invoiceUpdateFailedHandler } from './invoicePayment';
import { createDocFreeReturnOrderHandler } from './createDocFreeReturnOrder';
import { createDocBasedReturnOrderHandler } from './createDocBasedReturnOrder';
import {
  orderPaymentHandler,
  paymentSubmitHandler,
  paymentCancelledHandler,
  paymentFailedHandler,
} from './orderPayment';
import { handler as wishListHandler } from './wishList';
import { abandonedBasketHandler } from './restoreAbandonedBasket';

export { default as errorHandler } from './errorHandler';

const createSubAccountMiddleware = createAbilityMiddleware(AbilityTo.CreateSubAccount);
const useSalesAgreementsMiddleware = createAbilityMiddleware(AbilityTo.UseSalesAgreements);
const viewCatalogMiddleware = createAbilityMiddleware(AbilityTo.ViewCatalog);
const orderProductsMiddleware = createAbilityMiddleware(AbilityTo.OrderProducts);
const viewMyAccountMiddleware = createAbilityMiddleware(AbilityTo.ViewMyAccountPage);

export const handlers = {
  [RouteName.NotFound]: applyMiddleware(fallbackHandler, closedStoreMiddleware),
  [RouteName.Login]: loginPageHandler,
  [RouteName.Registration]: applyMiddleware(registrationHandler, closedStoreMiddleware),
  [RouteName.MyAccount]: applyMiddleware(createSystemPageHandler(), authorizeMiddleware, viewMyAccountMiddleware),
  [RouteName.ChangePassword]: applyMiddleware(createSystemPageHandler(), authorizeMiddleware, viewMyAccountMiddleware),
  [RouteName.EditProfile]: applyMiddleware(editProfileHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.EditProfile)),
  [RouteName.ForgotPassword]: forgotPasswordPageHandler,
  [RouteName.ResetPassword]: resetPasswordHandler,
  [RouteName.SubAccounts]: applyMiddleware(subAccountsHandler, authorizeMiddleware, createSubAccountMiddleware),
  [RouteName.NewSubAccount]: applyMiddleware(subAccountHandler, authorizeMiddleware, createSubAccountMiddleware),
  [RouteName.EditSubAccount]: applyMiddleware(subAccountHandler, authorizeMiddleware, createSubAccountMiddleware),
  [RouteName.CreateProspect]: applyMiddleware(createProspectHandler, authorizeMiddleware),
  [RouteName.Represent]: applyMiddleware(representHandler, authorizeMiddleware),
  [RouteName.Orders]: applyMiddleware(ordersHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewOrders)),
  [RouteName.Quotes]: applyMiddleware(quotesHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewQuotes)),
  [RouteName.Invoices]: applyMiddleware(invoicesHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewInvoices)),
  [RouteName.ReturnOrders]: applyMiddleware(returnOrdersHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewReturnOrders)),
  [RouteName.CreateDocFreeReturnOrder]: applyMiddleware(createDocFreeReturnOrderHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.CreateDocFreeReturnOrder)),
  [RouteName.CreateDocBasedReturnOrder]: applyMiddleware(createDocBasedReturnOrderHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.CreateDocBasedReturnOrder)),
  [RouteName.CreditNotes]: applyMiddleware(creditNotesHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewCreditNotes)),
  [RouteName.ReturnReceipts]: applyMiddleware(returnReceiptsHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewReturnReceipts)),
  [RouteName.Shipments]: applyMiddleware(shipmentsHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewShipments)),
  [RouteName.OrderAuthorizations]: applyMiddleware(orderAuthorizationsHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.AuthorizeOrders)),
  [RouteName.DocumentDetails]: applyMiddleware(documentHandler, authorizeMiddleware),
  [RouteName.OrderTemplates]: applyMiddleware(orderTemplatesHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.UseOrderTemplates)),
  [RouteName.SalesAgreements]: applyMiddleware(salesAgreementsHandler, authorizeMiddleware, useSalesAgreementsMiddleware),
  [RouteName.SalesAgreement]: applyMiddleware(salesAgreementHandler, authorizeMiddleware, useSalesAgreementsMiddleware),
  [RouteName.ContactUs]: applyMiddleware(createSystemPageHandler(), closedStoreMiddleware),
  [RouteName.Home]: applyMiddleware(homeHandler, closedStoreMiddleware),
  [RouteName.ContentPage]: applyMiddleware(contentHandler, closedStoreMiddleware),
  [RouteName.NewsOverview]: applyMiddleware(newsHandler, closedStoreMiddleware),
  [RouteName.NewsDetail]: applyMiddleware(newsDetailHandler, closedStoreMiddleware),
  [RouteName.NewsletterSubscribe]: applyMiddleware(createSystemPageHandler(), createAbilityMiddleware(AbilityTo.SubscribeToNewsletter), closedStoreMiddleware),
  [RouteName.NewsletterUnSubscribe]: applyMiddleware(createSystemPageHandler(), createAbilityMiddleware(AbilityTo.SubscribeToNewsletter), closedStoreMiddleware),
  [RouteName.Search]: applyMiddleware(searchHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [RouteName.ProductsWithCategory]: applyMiddleware(productsWithCategoryHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [RouteName.ProductDetails]: applyMiddleware(productDetailsHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [RouteName.ProductList]: applyMiddleware(productListHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [RouteName.ProductGroupPage]: applyMiddleware(productGroupPageHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [RouteName.VisualDesigner]: visualDesignerHandler,
  [RouteName.FaqPage]: applyMiddleware(faqHandler, closedStoreMiddleware),
  [RouteName.BasketPage]: applyMiddleware(basketHandler, closedStoreMiddleware, orderProductsMiddleware),
  [RouteName.WebsiteRedirect]: applyMiddleware(websiteRedirectHandler, closedStoreMiddleware),
  [RouteName.PagePreview]: previewHandler,
  [RouteName.LastViewedProducts]: applyMiddleware(lastViewedPageHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [RouteName.ProductComparison]: applyMiddleware(productComparisonPageHandler, closedStoreMiddleware, createAbilityMiddleware(AbilityTo.CompareProducts)),
  [RouteName.Checkout]: applyMiddleware(checkoutHandler, closedStoreMiddleware, orderProductsMiddleware),
  [RouteName.QuotePromotion]: applyMiddleware(checkoutHandler, authorizeMiddleware, closedStoreMiddleware, orderProductsMiddleware),
  [RouteName.OrderSubmit]: applyMiddleware(orderSubmitHandler, closedStoreMiddleware),
  [RouteName.OrderFailed]: applyMiddleware(orderFailedHandler, closedStoreMiddleware),
  [RouteName.OrderCancelled]: applyMiddleware(orderCancelledHandler, closedStoreMiddleware),
  [RouteName.QuotePromotionCancelled]: applyMiddleware(orderCancelledHandler, closedStoreMiddleware),
  [RouteName.PaymentError]: applyMiddleware(paymentErrorHandler, closedStoreMiddleware),
  [RouteName.InvoicePayment]: applyMiddleware(invoicePaymentHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.PayInvoice)),
  [RouteName.InvoiceUpdateFailed]: applyMiddleware(invoiceUpdateFailedHandler, closedStoreMiddleware),
  [RouteName.OrderPayment]: applyMiddleware(orderPaymentHandler, closedStoreMiddleware),
  [RouteName.PaymentSubmit]: applyMiddleware(paymentSubmitHandler, closedStoreMiddleware),
  [RouteName.PaymentCancelled]: applyMiddleware(paymentCancelledHandler, closedStoreMiddleware),
  [RouteName.PaymentFailed]: applyMiddleware(paymentFailedHandler, closedStoreMiddleware),
  [RouteName.WishList]: applyMiddleware(wishListHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.UseWishlist)),
  [RouteName.RestoreAbandonedBasket]: applyMiddleware(abandonedBasketHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.RestoreAbandonedBasket)),
  [RouteName.CxmlPunchoutError]: createSystemPageHandler(),
  [RouteName.OciPunchoutError]: createSystemPageHandler(),
};
