import type { ModifiedLine, SalesAgreementLine, ReceivedBasket, ReceivedSummary, SummaryModel, Model, SalesAgreementInfoData, SalesAgreementInfo } from './types';
import { pageSize } from './queries';
import { filterExistingImages } from 'utils/helpers';
import { isReceivedBasket, isSummaryModel } from './helpers';
import type { StandardEventSource, CustomEventSource } from 'behavior/analytics';

export const BASKET_ADD_PRODUCTS = 'BASKET_ADD_PRODUCTS' as const;
export const addProducts = (lines: BasketLineInput[], source?: StandardEventSource | CustomEventSource, updatedById?: string, agreementId: string | null = null) => ({
  type: BASKET_ADD_PRODUCTS,
  payload: { lines, source, updatedById, agreementId },
});

type BasketLineInput = {
  productId: string;
  variantId?: string;
  quantity: number;
  uomId?: string;
  salesAgreementLineId?: string;
};

export const BASKET_UPDATED = 'BASKET_UPDATED' as const;
export const basketUpdated = (updaterId: string | undefined, modifiedDate: number) => ({
  type: BASKET_UPDATED,
  payload: {
    date: modifiedDate,
    updaterId,
  },
});

export const BASKET_RECEIVED = 'BASKET_RECEIVED' as const;
export const basketReceived = (
  basket: ReceivedBasket | Model,
  salesAgreementInfo: SalesAgreementInfoData | SalesAgreementInfo | null = null,
  pageIndex = 0,
) => ({
  type: BASKET_RECEIVED,
  payload: {
    basket: isReceivedBasket(basket) ? filterImages(basket) : basket,
    salesAgreementInfo,
    page: {
      index: pageIndex,
      size: pageSize,
    },
  },
});

export const BASKET_PAGE_REQUESTED = 'BASKET_PAGE_REQUESTED' as const;
export const reloadBasketPage = (shouldCountSubLines = false) => ({
  type: BASKET_PAGE_REQUESTED,
  payload: { countSubLines: shouldCountSubLines },
});
export const requestBasketPage = (pageIndex: number, shouldCountSubLines = false) => ({
  type: BASKET_PAGE_REQUESTED,
  payload: { index: pageIndex, countSubLines: shouldCountSubLines },
});

export const BASKET_SUMMARY_REQUESTED = 'BASKET_SUMMARY_REQUESTED' as const;
export const requestBasketSummary = (calculated: boolean) => ({
  type: BASKET_SUMMARY_REQUESTED,
  payload: { calculated },
});

export const BASKET_SUMMARY_RECEIVED = 'BASKET_SUMMARY_RECEIVED' as const;
export const basketSummaryReceived = (
  basket: ReceivedSummary | SummaryModel | null,
  salesAgreementInfo?: SalesAgreementInfoData | SalesAgreementInfo | null,
  modifiedDate?: string | number | null,
) => ({
  type: BASKET_SUMMARY_RECEIVED,
  payload: {
    basket: basket && isSummaryModel(basket) ? basket : filterImages(basket),
    salesAgreementInfo,
    modifiedDate,
  },
});

export const BASKET_UPDATE = 'BASKET_UPDATE' as const;
export const modifyBasket = (modified: ModifiedLine[] | null = null, code: string | null = null, countSubLines = false, index?: number) => ({
  type: BASKET_UPDATE,
  payload: { modified, code, countSubLines, index },
});
export const saveModifiedBasket = (modified: ModifiedLine[], writeOnly = true) => ({
  type: BASKET_UPDATE,
  payload: { modified, writeOnly },
});

export const BASKET_CLEAR = 'BASKET_CLEAR' as const;
export const clearBasket = (remove = false) => ({
  type: BASKET_CLEAR,
  payload: { remove },
});

export const BASKET_REMOVE_AGREEMENT = 'BASKET_REMOVE_AGREEMENT' as const;
export const removeAgreement = () => ({
  type: BASKET_REMOVE_AGREEMENT,
});

export const BASKET_AGREEMENT_LINES_REQUESTED = 'BASKET_AGREEMENT_LINES_REQUESTED' as const;
export const requestAgreementLines = (agreementId: string, productId: string, basketLineId: string) => ({
  type: BASKET_AGREEMENT_LINES_REQUESTED,
  payload: { agreementId, productId, basketLineId },
});

export const BASKET_AGREEMENT_LINES_RECEIVED = 'BASKET_AGREEMENT_LINES_RECEIVED' as const;
export const receiveAgreementLines = (agreementLines: SalesAgreementLine[], basketLineId: string) => ({
  type: BASKET_AGREEMENT_LINES_RECEIVED,
  payload: { agreementLines, basketLineId },
});

export const BASKET_BROADCAST = 'BASKET_BROADCAST' as const;

// Creates action to notify another tabs about new basket data.
export const broadcastBasket = (payload: BroadcastData) => ({ type: BASKET_BROADCAST, payload });
type BroadcastData = ({ basket: Model } | { summary: SummaryModel | null }) & {
  language: number | null;
  modifiedDate: number;
  salesAgreementInfo: SalesAgreementInfo;
};

export const BASKET_ARRIVED = 'BASKET_ARRIVED' as const;
export const basketArrived = (basket: Model, salesAgreementInfo: SalesAgreementInfo, modifiedDate: number) => ({
  type: BASKET_ARRIVED,
  payload: { basket, salesAgreementInfo, modifiedDate },
});

function filterImages<T extends ReceivedBasket | ReceivedSummary | null>(basket: T): T {
  if (basket?.productLines.list)
    for (const line of basket.productLines.list)
      if ('product' in line && line.product?.images)
        line.product.images = filterExistingImages(line.product.images);

  return basket;
}

export type AddProductsAction = ReturnType<typeof addProducts>;
export type BasketUpdatedAction = ReturnType<typeof basketUpdated>;
export type BasketReceivedAction = ReturnType<typeof basketReceived>;
export type BasketSummaryReceivedAction = ReturnType<typeof basketSummaryReceived>;
export type BasketArrivedAction = ReturnType<typeof basketArrived>;
export type ReceiveAgreementLinesAction = ReturnType<typeof receiveAgreementLines>;
export type BasketUpdateAction = ReturnType<typeof modifyBasket | typeof saveModifiedBasket>;
export type RequestBasketPageAction = ReturnType<typeof requestBasketPage>;
export type ReloadBasketPageAction = ReturnType<typeof reloadBasketPage>;
export type BroadcastBasketAction = ReturnType<typeof broadcastBasket>;
export type BasketAction =
  | AddProductsAction
  | BasketUpdatedAction
  | BasketReceivedAction
  | BasketSummaryReceivedAction
  | BasketArrivedAction
  | ReceiveAgreementLinesAction
  | BasketUpdateAction
  | RequestBasketPageAction
  | ReloadBasketPageAction
  | ReturnType<
    | typeof requestBasketSummary
    | typeof clearBasket
    | typeof requestAgreementLines
  >;
