import { ofType } from 'redux-observable';
import { of, EMPTY, merge } from 'rxjs';
import { mergeMap, map, filter, take, takeUntil, pluck, startWith, switchMap } from 'rxjs/operators';
import { CheckoutPresets } from 'behavior/settings/constants';
import { APP_INIT_HYDRATE, APP_HYDRATED } from 'behavior/app';
import { skipIfPreview } from 'behavior/preview';
import { NAVIGATION_REQUESTED, navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { CHECKOUT_INFO_UPDATED, checkoutInfoUpdated, CHECKOUT_ADD_COUPON } from './actions';
import { getInvalidStepNavigationInfo, getStepByUrlHash, navigateOnIncorrect } from './helpers';
import { Steps } from './constants';
import { addCouponMutation } from './queries';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { toasts } from 'behavior/toasts';

export default function multiStepEpic(action$, state$, { api, scope }) {
  if (scope === 'SERVER')
    return EMPTY;

  const redirectToInvalidStep$ = action$.pipe(
    ofType(APP_INIT_HYDRATE, CHECKOUT_INFO_UPDATED),
    filter(_ => isMultiStepCheckout(state$)),
    skipIfPreview(state$),
    mergeMap(action => {
      const currentStep = getStepByUrlHash(state$.value.routing.location.hash);
      if (currentStep) {
        const info = state$.value.page.info;
        const steps = action.type === CHECKOUT_INFO_UPDATED ? action.payload.steps : info.steps;
        if (steps) {
          const navInfo = getInvalidStepNavigationInfo(steps, currentStep, state$.value.routing.location, !!info.quote, !!info.isQuote, !!info.isGuest);
          if (navInfo)
            return of(navigateTo(navInfo.to, navInfo.url));
        }
      }

      return EMPTY;
    }),
  );

  const setCurrentStepOnHydrated$ = action$.pipe(
    ofType(APP_HYDRATED),
    take(1),
    takeUntil(action$.pipe(ofType(NAVIGATION_REQUESTED))),
    filter(_ => {
      const info = state$.value.page.info;
      return isMultiStepCheckout(state$) && info?.currentStep === Steps.None;
    }),
    map(_ => checkoutInfoUpdated({ currentStep: getStepByUrlHash(state$.value.routing.location.hash) })),
  );

  const addCoupon$ = action$.pipe(
    ofType(CHECKOUT_ADD_COUPON),
    switchMap(({ payload }) => api.graphApi(addCouponMutation, {
      code: payload.code,
    }).pipe(
      pluck('checkout', 'checkoutDiscount', 'addCoupon'),
      mergeMap(({ info }) => {
        if (info.valid) {
          const toastTextKey = !!info.discount.promotion ? 'Checkout_PromotionAppied' : 'Checkout_PromotionRemoved';
          toasts.success('', { textKey: toastTextKey });
          return of(checkoutInfoUpdated(info), unsetLoadingIndicator());
        }

        return of(navigateOnIncorrect(state$.value.page.info));
      }),
      startWith(setLoadingIndicator()),
    ),
    ));

  return merge(
    redirectToInvalidStep$,
    setCurrentStepOnHydrated$,
    addCoupon$,
  );
}

function isMultiStepCheckout(state$) {
  const routeName = state$.value.routing.routeData.routeName;
  const pagePreset = state$.value.settings.checkout.pagePreset;
  return routeName === RouteName.Checkout && pagePreset === CheckoutPresets.MultiStep;
}
