import { useSanaTexts } from 'components/sanaText';
import { useSelector } from 'react-redux';
import { makeSimpleText } from 'utils/render';
import { useServices } from '../services';
import { ProductTrackingOptions, TrackingOptions, CategoriesTexts } from './types';

export const useProductTrackingOptions = (uomTitleKey?: string): ProductTrackingOptions => {
  const { context: { origin } } = useServices();
  const [brand] = useSanaTexts<string | null>(['RichSnippets_BrandName'], makeSimpleText).texts;

  return useSelector(({
    user: { currencyId },
    settings,
    sanaTexts,
  }) => ({
    origin,
    currencyId,
    noImageLarge: settings.loaded ? settings.product.noImage.large : undefined,
    uomTitle: uomTitleKey && sanaTexts[uomTitleKey],
    brand,
  }),
    _ => true,
  );
};

export const useTrackingOptions = (): TrackingOptions => {
  const { context: { origin } } = useServices();
  return { origin };
};

export const useCategoriesTexts = (): CategoriesTexts => {
  const textPrefix = 'LM_GoogleShoppingFeed__GoogleCategory';
  const [
    software,
    books,
    footProtection,
    bodyProtection,
    eyeProtection,
    handProtection,
    headProtection,
  ] =
    useSanaTexts<string | null>([
      `${textPrefix}_Software`,
      `${textPrefix}_Books`,
      `${textPrefix}_FootProtection`,
      `${textPrefix}_BodyProtection`,
      `${textPrefix}_EyeProtection`,
      `${textPrefix}_HandProtection`,
      `${textPrefix}_HeadProtection`,
    ], makeSimpleText).texts;

  return {
    software,
    books,
    footProtection,
    bodyProtection,
    eyeProtection,
    handProtection,
    headProtection,
  };
};
