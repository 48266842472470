import { createContext } from 'react';
import { ComponentGroup } from 'behavior/navigation/constants';

type CascadingMenuContext = {
  componentGroup: ComponentGroup.CascadingMainMenu;
  endNodesNumber: number | null;
};

type MultiColumnMenuContext = {
  componentGroup: ComponentGroup.MultiColumnMainMenu;
  showViewMoreLink: boolean;
};

export default createContext<CascadingMenuContext | MultiColumnMenuContext>({
  componentGroup: ComponentGroup.CascadingMainMenu,
  endNodesNumber: null,
});
