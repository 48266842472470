type SuggestedAddress = {
  address: string;
  address2: string | null;
  city: string;
  countryId: string;
  state: string;
  zipCode: string;
};

export type AddressValidationResult = {
  isValidAddress: boolean;
  suggestedAddresses?: SuggestedAddress[] | null;
  addressStatus: string;
};

export type CreationResult = {
  contactId?: string | null;
  failureText?: string | null;
  isCreated?: boolean;
  addressValidationResult?: AddressValidationResult;
} | null;

export const CREATE_PROSPECT_PROCESSED = 'CREATE_PROSPECT_PROCESSED' as const;
export const createProspectProcessed = (result: CreationResult) => ({ type: CREATE_PROSPECT_PROCESSED, payload: result });

export type CreateProspectAction = ReturnType<
  | typeof createProspectProcessed
>;
