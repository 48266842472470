import { ViewModes } from './constants';

export function getViewModeProductAmount(viewMode, settings) {
  const { gridProductAmount, listProductAmount } = settings;

  if (viewMode === ViewModes.Grid)
    return gridProductAmount;

  if (viewMode === ViewModes.List)
    return listProductAmount;
}

export function getDefaultContentAmount(settings) {
  const { listContentAmount } = settings;

  return listContentAmount;
}

const _desc = '_desc';

export function getSortOptionKey(field, ascending) {
  const normalized = field.toLowerCase();
  return ascending ? normalized : normalized + _desc;
}

export function parseSortOption(value) {
  return value.endsWith(_desc)
    ? { field: value.slice(0, -_desc.length), ascending: false }
    : { field: value, ascending: true };
}

export function sortOptionsAreEqual(option1, option2) {
  return option1
    && option2
    && option1.field.toLowerCase() === option2.field.toLowerCase()
    && option1.ascending === option2.ascending;
}