import styles from '../MainNav.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ComponentGroup } from 'behavior/navigation/constants';
import MobileNav from './MobileNav';
import NavMenuContext from '../NavMenuContext';
import { joinClasses } from 'utils/helpers';

const MainMenuBlock = ({ model, id, isDesignerMode }) => {
  const context = useMemo(() => ({
    componentGroup: ComponentGroup.CascadingMainMenu,
    endNodesNumber: model.megaMenu_EndNodesNumber,
  }), [model]);
  const navId = `MobileMainNav_${id}`;

  const style = useMemo(() => ({
    '--topLevel-fontFamily': model.topLevel_FontFamily && (model.topLevel_FontFamily + ', sans-serif'),
    '--topLevel-fontSize': model.topLevel_FontSize,
    '--topLevel-allCaps': model.topLevel_AllCaps ? 'uppercase' : null,
    '--topLevel-fontColor': model.topLevel_FontColor,
    '--topLevel-activeItemUnderlineColor': model.topLevel_ActiveItemUnderlineColor,
    '--topLevel-drawerBackgroundColor': model.topLevel_DrawerBackgroundColor,
    '--subLevel-fontFamily': model.subLevel_FontFamily && (model.subLevel_FontFamily + ', sans-serif'),
    '--subLevel-fontSize': model.subLevel_FontSize,
    '--subLevel-allCaps': model.subLevel_AllCaps ? 'uppercase' : null,
    '--subLevel-fontColor': model.subLevel_FontColor,
    '--megaMenu-topLevelFontFamily': model.megaMenu_TopLevelFontFamily && (model.megaMenu_TopLevelFontFamily + ', sans-serif'),
    '--megaMenu-topLevelFontSize': model.megaMenu_TopLevelFontSize,
    '--megaMenu-topLevelFontColor': model.megaMenu_TopLevelFontColor,
    '--megaMenu-secondLevelFontSize': model.megaMenu_SecondLevelFontSize,
    '--megaMenu-secondLevelFontColor': model.megaMenu_SecondLevelFontColor,
    '--megaMenu-thirdLevelFontSize': model.megaMenu_ThirdLevelFontSize,
    '--megaMenu-thirdLevelFontColor': model.megaMenu_ThirdLevelFontColor,
  }), [model]);

  const className = joinClasses(
    model.topLevel_ShowActiveItemUnderline && styles.showActiveItemUnderline,
    model.subLevel_ShowArrowInFrontOfSubItem && styles.showSubItemArrow,
  );

  const openerStyle = useMemo(() => ({
    '--toggle-iconColor': model.toggle_IconColor,
    '--toggle-backgroundColor': model.toggle_BackgroundColor,
  }), [model]);

  return (
    <NavMenuContext.Provider value={context}>
      <MobileNav
        isDesignerMode={isDesignerMode}
        id={navId}
        style={style}
        className={className}
        openerStyle={openerStyle}
      />
    </NavMenuContext.Provider>
  );
};

MainMenuBlock.propTypes = {
  model: PropTypes.shape({
    toggle_IconColor: PropTypes.string,
    toggle_BackgroundColor: PropTypes.string,
    topLevel_FontFamily: PropTypes.string,
    topLevel_FontSize: PropTypes.string,
    topLevel_AllCaps: PropTypes.bool.isRequired,
    topLevel_FontColor: PropTypes.string,
    topLevel_ShowActiveItemUnderline: PropTypes.bool.isRequired,
    topLevel_ActiveItemUnderlineColor: PropTypes.string,
    topLevel_DrawerBackgroundColor: PropTypes.string,
    subLevel_FontFamily: PropTypes.string,
    subLevel_FontSize: PropTypes.string,
    subLevel_AllCaps: PropTypes.bool.isRequired,
    subLevel_FontColor: PropTypes.string,
    subLevel_ShowArrowInFrontOfSubItem: PropTypes.bool.isRequired,
    megaMenu_TopLevelFontFamily: PropTypes.string,
    megaMenu_TopLevelFontSize: PropTypes.string,
    megaMenu_TopLevelFontColor: PropTypes.string,
    megaMenu_SecondLevelFontSize: PropTypes.string,
    megaMenu_SecondLevelFontColor: PropTypes.string,
    megaMenu_ThirdLevelFontSize: PropTypes.string,
    megaMenu_ThirdLevelFontColor: PropTypes.string,
    megaMenu_EndNodesNumber: PropTypes.number,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default MainMenuBlock;
