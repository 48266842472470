import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import type { ProductTrackingData, StandardEventSource, CustomEventSource } from 'behavior/analytics';
import { trackProductClick } from 'behavior/analytics';
import ProductTrackingContext from './ProductTrackingContext';

type TrackingArgs = {
  product: ProductTrackingData;
  trackingSource: StandardEventSource | CustomEventSource;
};

type Props = {
  children: (clickHandler: () => void) => ReactElement;
  data?: TrackingArgs;
};

const WithProductClickTracking = ({ children, data }: Props) => {
  const dispatch = useDispatch();
  const { product, source } = getParams(data);

  const onClickHandler = useCallback(() => {
    product && source && dispatch(trackProductClick({ product, source }));
  }, [product, source]);

  return children(onClickHandler);
};

export default WithProductClickTracking;

const getParams = (data?: TrackingArgs) => {
  if (data === undefined) {
    const context = useContext(ProductTrackingContext);
    return {
      product: context.product,
      source: context.trackingSource,
    };
  }

  return {
    product: data.product,
    source: data.trackingSource,
  };
};
