import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { filter, ignoreElements, tap, map, delay } from 'rxjs/operators';
import { AnalyticsAction } from './actions';
import { ANALYTICS_DATALAYER_READY } from './actions';
import { NAVIGATED, NavigatedAction } from 'behavior/routing';
import { skipIfPreview } from 'behavior/preview';
import { getDataLayer } from './dataLayer';

const epic: Epic<AnalyticsAction | NavigatedAction> = (action$, state$) => action$.pipe(
  ofType(ANALYTICS_DATALAYER_READY, NAVIGATED),
  filter(_ => !!state$.value.analytics?.isDataLayerReady),
  skipIfPreview(state$),
  map(action => {
    const dataLayer = getDataLayer(state$.value);
    return { action, dataLayer };
  }),
  filter(({ action, dataLayer }) => {
    if (!dataLayer.pageView.isSupported)
      return false;

    if (action.type === NAVIGATED)
      return !action.payload.routeData.options?.skipTracking;

    return !state$.value.routing.navigatingTo;
  }),
  delay(500), // Delay for page title to be set.
  tap(({ dataLayer }) => {
    const inputData = {
      origin: window.location.origin,
      pageTitle: window.document.title,
      state: state$.value,
    };
    dataLayer.pageView.push(inputData);
  }),
  ignoreElements(),
);

export default epic;
