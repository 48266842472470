import { ResentlyPurchasedProductsAction, RECEIVE_RECENTLY_PURCHASED_PRODUCTS } from './actions';
import { 
  MostPurchasedProduct as GeneralMostPurchasedProduct, 
  ResentlyPurchasedProduct, 
  CalculatedMostPurchasedProduct,
} from './types';

type MostPurchasedProduct = GeneralMostPurchasedProduct 
  | GeneralMostPurchasedProduct & CalculatedMostPurchasedProduct;

type ResentlyPurchasedProductsState = {
  mostPurchasedProduct: MostPurchasedProduct | null;
  products: ResentlyPurchasedProduct[] | null;
  loaded: boolean;
};

const initialState: ResentlyPurchasedProductsState = {
  mostPurchasedProduct: null,
  products: null,
  loaded: false,
};

export default (state = initialState, action: ResentlyPurchasedProductsAction) => {
  switch (action.type) {
    case RECEIVE_RECENTLY_PURCHASED_PRODUCTS:
      if (!action.payload)
        return state;

      if (isGeneralMostPurchasedProduct(action.payload.mostPurchasedProduct))
        return { 
          ...state, 
          ...action.payload, 
          loaded: true, 
        };

      return { 
        ...state, 
        ...action.payload,
        mostPurchasedProduct: {
          ...state.mostPurchasedProduct,
          ...action.payload.mostPurchasedProduct,
        },
      };
    default:
      return state;
  }
};

function isGeneralMostPurchasedProduct(product: GeneralMostPurchasedProduct | CalculatedMostPurchasedProduct | null): product is GeneralMostPurchasedProduct {
  return !product || 'title' in product;
}