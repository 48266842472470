import type { Epic } from 'behavior/types';
import type { WishListAction } from './actions';
import type { LoadedSettings } from 'behavior/settings';
import type { AppState } from 'behavior';
import type { Action } from 'redux';
import type { RoutingAction } from 'behavior/routing';
import type { ModifiedLines } from 'behavior/basket';
import { ofType, StateObservable } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  pluck,
  switchMap,
  startWith,
  mergeMap,
} from 'rxjs/operators';
import {
  WISH_LIST_ADD_PRODUCT,
  WISH_LIST_ADD_BASKET,
  WISH_LIST_REMOVE_PRODUCT,
  WISH_LIST_MOVE_PRODUCT_TO_BASKET,
  updateWishList,
  productRemovedFromWishList,
} from './actions';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import {
  addProductMutation,
  addBasketMutation,
  removeProductMutation,
  moveProductToBasketMutation,
} from './queries';
import { trackAddToBasket, getProductsTrackingDataFromLines, StandardEventSource } from 'behavior/analytics';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { skipIfPreviewWithToast } from 'behavior/preview';

const wishListEpic: Epic<WishListAction | RoutingAction> = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;
  const addProductToWishListAction$ = action$.pipe(
    ofType(WISH_LIST_ADD_PRODUCT),
    skipIfPreviewWithToast(state$, dependencies),
    pluck('payload'),
    switchMap(input =>
      api.graphApi(addProductMutation, { input }).pipe(
        mergeMap(_ => [updateWishList(Date.now()), unsetLoadingIndicator()]),
        retryWithToast(action$, logger, _ => of(unsetLoadingIndicator())),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const addBasketToWishListAction$ = action$.pipe(
    ofType(WISH_LIST_ADD_BASKET),
    skipIfPreviewWithToast(state$, dependencies),
    switchMap(_ =>
      api.graphApi(addBasketMutation).pipe(
        mergeMap(_ => [updateWishList(Date.now()), unsetLoadingIndicator()]),
        retryWithToast(action$, logger, _ => of(unsetLoadingIndicator())),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const removeProductFromWishListAction$ = action$.pipe(
    ofType(WISH_LIST_REMOVE_PRODUCT),
    pluck('payload'),
    switchMap(input =>
      api.graphApi(removeProductMutation, { input }).pipe(
        mergeMap(_ => [productRemovedFromWishList(input), unsetLoadingIndicator()]),
        retryWithToast(action$, logger, _ => of(unsetLoadingIndicator())),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const moveProductToBasketAction$ = action$.pipe(
    ofType(WISH_LIST_MOVE_PRODUCT_TO_BASKET),
    pluck('payload'),
    switchMap(input =>
      api.graphApi<MoveProductToBasketResponse>(moveProductToBasketMutation, { input, requestModifiedLines: isTrackingEnabled(state$) }).pipe(
        pluck('wishList', 'moveToBasket', 'modifiedLines'),
        mergeMap(modifiedLines => {
          const settings = state$.value.settings as LoadedSettings;
          const actions: Action[] = [
            basketChangeCompleted(1),
            productRemovedFromWishList(input),
          ];

          const addedProducts = getProductsTrackingDataFromLines(modifiedLines?.list);
          if (addedProducts && addedProducts.length) {
            actions.push(trackAddToBasket({ products: addedProducts, source: StandardEventSource.WishList }));
          }

          if (settings.basket.redirectOnAdd)
            actions.push(navigateTo(routesBuilder.forBasket()));

          return actions;
        }),
        retryWithToast(action$, logger, _ => of(basketChangeCompleted(0))),
        startWith(basketChangeStarted()),
      ),
    ),
  );

  return merge(
    addProductToWishListAction$,
    addBasketToWishListAction$,
    removeProductFromWishListAction$,
    moveProductToBasketAction$,
  );
};

export default wishListEpic;

function isTrackingEnabled(state$: StateObservable<AppState>): boolean {
  return !!state$.value.analytics?.isTrackingEnabled;
}

type MoveProductToBasketResponse = {
  wishList: {
    moveToBasket: {
      modifiedLines?: ModifiedLines;
    };
  };
};
