import type { Product, PerfionComparisonTab, PerfionTab } from './types';

const excludeUndefined = <T>(x: T | undefined): x is T => !!x;
export const sortByIds = (products: Product[] | undefined, ids: string[] = []): Product[] => {
  if (!products || !products.length)
    return [];
  return ids.map(id => products.find(p => p.id.toLocaleUpperCase() === id.toLocaleUpperCase())).filter(excludeUndefined);
};

export const buildPerfionComparisonTabsModel = (perfionTabsSettings: string[], products: Product[] | undefined): PerfionComparisonTab[] => {
  const comparisonTabs: PerfionComparisonTab[] = [];

  if (!products?.length || !perfionTabsSettings?.length)
    return comparisonTabs;

  const productsCount = products.length;

  products.forEach((product, productIndex) => {
    product.perfionTabs.filter(el => perfionTabsSettings.includes(el.title)).forEach(tab => {
      const comparisonTab = comparisonTabs.find(ct => ct.id === tab.id);
      if (comparisonTab) {
        addOrUpdateFieldValues(comparisonTab, tab, productsCount, productIndex);
      }
      else {
        const newComparisonTab: PerfionComparisonTab = {
          id: tab.id,
          name: tab.title,
          orderNo: tab.orderNo,
          fields: [],
        };

        addOrUpdateFieldValues(newComparisonTab, tab, productsCount, productIndex);

        comparisonTabs.push(newComparisonTab);
      }
    });
  });

  comparisonTabs.forEach(ct => {
    ct.fields.forEach(f => {
      f.hasDifferences = !f.values.every(v => v.value === f.values[0].value);
    });
  });

  return comparisonTabs;
};

const addOrUpdateFieldValues = (comparisonTab: PerfionComparisonTab, perfionTab: PerfionTab, productsCount: number, productIndex: number) => {
  perfionTab.fields.forEach(f => {
    let comaprisonField = comparisonTab.fields.find(cf => cf.key === f.xmlNodeName);
    if (comaprisonField) {
      comaprisonField.values[productIndex] = { value: f.value, fileUrl: f.fileUrl };
    }
    else {
      const valuesArray = new Array(productsCount).fill({ value: null, fileUrl: null });
      valuesArray[productIndex] = { value: f.value, fileUrl: f.fileUrl };

      comaprisonField = {
        key: f.xmlNodeName,
        name: f.title,
        orderNo: f.orderNo,
        hasDifferences: false,
        fieldType: f.fieldType,
        values: valuesArray,
      };

      comparisonTab.fields.push(comaprisonField);
    }
  });
};