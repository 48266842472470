import { ResentlyPurchasedProducts, CalculatedResentlyPurchasedProducts } from './types';

export const REQUEST_RECENTLY_PURCHASED_PRODUCTS = 'REQUEST_RECENTLY_PURCHASED_PRODUCTS' as const;
export const requestRecentlyPurchasedProducts = () => ({
  type: REQUEST_RECENTLY_PURCHASED_PRODUCTS,
});

export const RECEIVE_RECENTLY_PURCHASED_PRODUCTS = 'RECEIVE_RECENTLY_PURCHASED_PRODUCTS' as const;
export const receiveRecentlyPurchasedProducts = (resentlyPurchasedProducts?: ResentlyPurchasedProducts | CalculatedResentlyPurchasedProducts) => ({
  type: RECEIVE_RECENTLY_PURCHASED_PRODUCTS,
  payload: resentlyPurchasedProducts,
});

export type ResentlyPurchasedProductsAction = ReturnType<
  | typeof requestRecentlyPurchasedProducts
  | typeof receiveRecentlyPurchasedProducts
>;