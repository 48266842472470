import styles from '../MainNav.module.scss';
import { memo, useRef, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { SublistParamsPropTypes } from '../PropTypes';
import { SublistPositionContext, Positions } from '../SublistPositionProvider';
import { removeListHoverState } from '../eventHandlers';
import { useIsTouchScreen } from 'utils/detections';
import { useIsMouse } from 'components/detection';
import { hoveredItemClass, setFocus } from '../eventHandlers';
import { shouldRenderNavigation } from '../../helpers';
import { Link } from 'components/primitives/links';
import { useNavMenuContext } from '../hooks';
import { RichText } from 'components/sanaText';

const MegaViewSubItems = ({
  subItems,
  id,
  title,
  isAccordion,
  expanded,
  ariaSubmenuText,
  sublistParams,
}) => {
  const ref = useRef(null);
  const positionContext = useContext(SublistPositionContext);
  const isTouchScreen = useIsTouchScreen();
  const isMouse = useIsMouse();
  const subListLevel = 0;
  const { endNodesNumber } = useNavMenuContext();

  const posClass = useMemo(() => {
    if (isAccordion)
      return '';

    if (!positionContext || !sublistParams)
      return styles.leftPos;

    const position = positionContext.getSublistPosition(`${sublistParams.startOffset}_${sublistParams.direction}`, subListLevel);
    return position === Positions.LEFT ? styles.leftPos : styles.rightPos;
  }, [isAccordion, positionContext, subListLevel, sublistParams]);

  useEffect(() => {
    if (!ref.current.parentElement.classList.contains(hoveredItemClass))
      return;

    ref.current.setAttribute('aria-hidden', 'false');
    ref.current.setAttribute('aria-expanded', 'true');
  }, []);

  return (
    <div className={styles.subItemsWrapper}>
      <ul
        ref={ref}
        // Element should be focusable for proper event handling in Mobile Safari, Legacy MS Edge and IE11 when using laptop with touch screen.
        tabIndex="-1"
        id={id}
        className={`${styles.subItems} ${styles.focusable} ${posClass}`}
        // onMouseLeave is not reliable when using touch screen on laptop - it is fired twice for touch position and last known mouse cursor position.
        onMouseLeave={isTouchScreen && isMouse === false ? null : removeListHoverState}
        aria-label={`${title} ${ariaSubmenuText}`}
        aria-hidden={isAccordion ? !expanded : 'true'}
        aria-expanded={isAccordion ? expanded : 'false'}
      >
        {subItems.map(({ id, link, shortTitle, title, children, cssClass }) => (
          <li key={id} className={`${styles.navItemBlock} ${cssClass || ''}`}>
            <Link {...link} className={styles.hypSecondLvl} onClick={setFocus} onContextMenu={setFocus}>
              {shortTitle || title}
            </Link>
            {shouldRenderNavigation(children) && (
              <ul className={styles.navList} aria-label={`${shortTitle || title} ${ariaSubmenuText}`}>
                {children.slice(0, endNodesNumber).map(({ id, link, shortTitle, title, cssClass }) => {
                  return (
                    <li key={id} className={cssClass || null}>
                      <Link {...link} className={styles.hypDefault} onClick={setFocus} onContextMenu={setFocus}>
                        {shortTitle || title}
                      </Link>
                    </li>
                  );
                })}
                {endNodesNumber && children.length > endNodesNumber && link?.url !== '#' && (
                  <li>
                    <Link {...link} className={`${styles.hypDefault} ${styles.hypViewAll}`} onClick={setFocus}>
                      <RichText textKey="ViewAllOf" formatWith={[children.length, shortTitle || title]} />
                    </Link>
                  </li>
                )}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

MegaViewSubItems.propTypes = {
  subItems: PropTypes.array,
  id: PropTypes.string.isRequired,
  createItem: PropTypes.func.isRequired,
  title: PropTypes.string,
  isAccordion: PropTypes.bool,
  expanded: PropTypes.bool,
  ariaSubmenuText: PropTypes.string,
  sublistParams: SublistParamsPropTypes,
};

export default memo(MegaViewSubItems);
