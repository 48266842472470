import type { NavItem, MainMenuItem, MainMenuItemWithParent } from './types';
import type { Epic } from 'behavior/types';
import type { ComponentGroup, NavigationGroupCode } from './constants';
import { of, merge, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  map,
  mergeMap,
  pluck,
  groupBy,
  catchError,
  exhaustMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  NAVIGATION_LOAD,
  MAIN_NAVIGATION_CHILDREN_LOAD,
  PAGE_NAVIGATION_ITEMS_LOAD,
  loadNavigation,
  navigationLoaded,
  mainNavigationChildrenLoaded,
  pageNavigationItemsLoaded,
  NavigationAction,
} from './actions';
import { getQuery, loadMainNavigationChildrenQuery, pageNavigationItemsQuery } from './queries';
import { VIEWER_CHANGED, LANGUAGE_CHANGED, LOCATION_CHANGED } from 'behavior/events';
import { USER_PROFILE_UPDATED } from 'behavior/user';
import { retryWithToast } from 'behavior/errorHandling';
import { handleError, ERROR_PRIORITIES } from 'utils/rxjs';

const epic: Epic<NavigationAction> = (action$, state$, { api, logger }) => {
  const reload$ = action$.pipe(
    ofType(LANGUAGE_CHANGED, VIEWER_CHANGED, USER_PROFILE_UPDATED),
    mergeMap(() => Object.entries(state$.value.navigation).flatMap(entry => {
      const componentGroup = entry[0] as ComponentGroup;

      const groupCodes = Object.keys(entry[1]!) as NavigationGroupCode[];

      return groupCodes.map(groupCode => loadNavigation(componentGroup, groupCode));
    })),
  );

  const fromAction$ = action$.pipe(ofType(NAVIGATION_LOAD));

  const loadNavigation$ = merge(reload$, fromAction$).pipe(
    pluck('payload'),
    groupBy(({ componentGroup, groupCode }) => `${componentGroup}:${groupCode}`),
    mergeMap(group => group.pipe(
      exhaustMap(({ componentGroup, groupCode }) => {
        const query = getQuery(componentGroup, groupCode);
        if (!query) {
          logger.error(`Query for ${componentGroup}:${groupCode} is not registered.`);
          return EMPTY;
        }

        return api.graphApi<NavigationResponse<NavItem>>(query).pipe(
          pluck('navigation', 'items'),
          map(items => navigationLoaded(componentGroup, groupCode, items)),
          state$.value.navigation[componentGroup]?.[groupCode]
            ? retryWithToast(action$, logger)
            : handleError(ERROR_PRIORITIES.HIGH, 'navigation', _ => of(navigationLoaded(componentGroup, groupCode, null))),
        );
      }),
    )),
  );

  const loadChildren$ = action$.pipe(
    ofType(MAIN_NAVIGATION_CHILDREN_LOAD),
    map(action => action.payload),
    groupBy(({ componentGroup, parentId }) => `${componentGroup}:${parentId}`),
    mergeMap(group => group.pipe(
      exhaustMap(({ componentGroup, parentId }) => api.graphApi<NavigationResponse<MainMenuItem>>(loadMainNavigationChildrenQuery, { id: parentId }).pipe(
        pluck('navigation', 'items'),
        map(items => mainNavigationChildrenLoaded(componentGroup, parentId, items)),
        catchError(e => {
          logger.error(e);
          return of(mainNavigationChildrenLoaded(componentGroup, parentId, null));
        }),
      )),
    )),
  );

  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  const loadPageNavigationItems$ = action$.pipe(
    ofType(PAGE_NAVIGATION_ITEMS_LOAD),
    pluck('payload', 'url'),
    switchMap(
      url => api.graphApi<LoadPageNavigationItemsResponse>(pageNavigationItemsQuery, { url }).pipe(
        pluck('navigation', 'findByUrl'),
        map(item => {
          const items: MainMenuItemWithParent[] = [];
          let currentItem = item;

          while (currentItem) {
            items.push(currentItem);
            currentItem = currentItem.parent;
          }


          return pageNavigationItemsLoaded(items.reverse());
        }),
        catchError(e => {
          logger.error(e);
          return EMPTY;
        }),
        takeUntil(locationChanged$),
      ),
    ),
  );

  return merge(loadNavigation$, loadChildren$, loadPageNavigationItems$);
};

export default epic;

type NavigationResponse<T> = {
  navigation: {
    items: T[];
  };
};

type LoadPageNavigationItemsResponse = {
  navigation: {
    findByUrl: MainMenuItemWithParent | null;
  };
};