import { useCallback, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getSortOptionKey, sortOptionsAreEqual } from 'behavior/pages/productList';
import { getViewModeProductAmount } from 'behavior/pages/productList/helpers';
import { stringifyQuery, parseQuery } from 'utils/url';
import { routesBuilder } from 'routes';
import { useOnChange, useCurrentRouteAsBackTo } from 'utils/hooks';
import { setStatusTextKey } from 'behavior/ariaStatus';
import { useSanaTexts } from 'components/sanaText';
import { useLoadEffect } from 'utils/hooks';

export const useUrlBuilder = (...middlewares) => {
  const { pathname, search, routeData, canonicalUrl, ...options } = useSelector(({
    routing: {
      routeData,
      location: { pathname, search },
      canonicalUrl,
    },
    settings: {
      productList: {
        listProductAmount,
        gridProductAmount,
      },
    },
    page: {
      defaultViewMode,
      defaultSorting,
    },
  }) => ({
    pathname,
    search,
    routeData,
    canonicalUrl,
    listProductAmount,
    gridProductAmount,
    defaultViewMode,
    defaultSorting,
  }), shallowEqual);

  const canonicalSearch = canonicalUrl ? canonicalUrl.split('?')[1] || '' : search;

  return (params, routeOptions) => {
    if (!params.count) {
      const viewMode = params.viewMode || routeData.params.viewMode || options.defaultViewMode;
      params.count = getViewModeProductAmount(viewMode, options);
    }

    params = { ...routeData.params, ...params };
    const { count, sort, 'view-mode': viewMode, page, ...query } = parseQuery(canonicalSearch);

    addSortingParam(query, params, options);
    addViewModeParam(query, params, options);

    for (const middleware of middlewares)
      middleware(query, params, options);

    return {
      url: pathname + stringifyQuery(query),
      to: {
        ...routeData,
        params,
        options: routeOptions,
      },
    };
  };
};

function addSortingParam(query, { sort }, options) {
  if (sort && !sortOptionsAreEqual(sort, options.defaultSorting))
    query.sort = getSortOptionKey(sort.field, sort.ascending);
}

function addViewModeParam(query, { viewMode }, options) {
  if (viewMode && viewMode !== options.defaultViewMode)
    query['view-mode'] = viewMode.toLowerCase();
}

export const useLoadMoreUrlBuilder = (nextPageCount, nextPage, pageSize, loadedProductsCount, loadedContentItemsCount, contentOnly = false) => {
  const productToLoadCount = contentOnly ? loadedProductsCount : nextPageCount;
  const contentToLoadCount = contentOnly ? nextPageCount : loadedContentItemsCount;

  const pagingMiddleware = (query, _params) => {
    query.count = productToLoadCount;
    query.contentcount = contentToLoadCount;
  };

  const result = {
    page: nextPage,
    size: pageSize,
    productsOnly: true,
    appendProducts: true,
    contentOnly: false,
  };

  if (contentOnly) {
    result.productsOnly = false;
    result.appendProducts = false;
    result.contentOnly = true;
    result.contentSize = pageSize;
  }

  const { url, to } = useUrlBuilder(pagingMiddleware)({ page: 0, count: productToLoadCount, contentCount: contentToLoadCount }, result);

  return { url, to };
};

export function useProductRouteBuilder(id, withoutBackTo, salesAgreementLineId) {
  if (withoutBackTo)
    return useCallback(() => routesBuilder.forProduct(id, undefined, salesAgreementLineId), [id, salesAgreementLineId]);

  const backTo = useCurrentRouteAsBackTo();
  return useCallback(() => routesBuilder.forProduct(id, backTo, salesAgreementLineId), [id, backTo, salesAgreementLineId]);
}

export function useAriaStatusNotification(products, selectedViewMode, selectedSorting) {
  // Preload text.
  useSanaTexts(['Aria_PLP_ListUpdated']);
  const dispatch = useDispatch();
  const firstId = products && products.length && products[0].id;

  // Memoize view mode & sorting to prevent anouncing before new products received.
  [selectedViewMode, selectedSorting] =
    useMemo(() => [selectedViewMode, selectedSorting], [products]);

  useOnChange(() => {
    if (firstId)
      setTimeout(() => dispatch(setStatusTextKey('Aria_PLP_ListUpdated')), 150);
  }, [products.length, firstId, selectedViewMode, selectedSorting], false);
}

export function useTracking(products, callback, deps) {
  useLoadEffect(() => {
    const productsToTrack = products
      .map((product, listIndex) => [product, listIndex])
      .filter(([product, _]) => product.calculatedInfoChanged)
      .map(([product, listIndex]) => ({ ...product, listIndex }));

    if (productsToTrack.length > 0) {
      callback({
        products: productsToTrack,
      });
    }
  }, deps);
}

export function usePagingSettings() {
  const params = useSelector(state => state.routing.routeData.params);
  const { selectedViewMode, defaultViewMode } = useSelector(state => state.page);
  const settings = useSelector(state => state.settings.productList);

  if (!params) {
    return {
      pageIndex: 0,
      pageSize: getViewModeProductAmount(selectedViewMode || defaultViewMode, settings),
    };
  }

  return {
    pageIndex: params.page ? params.page - 1 : 0,
    pageSize: params.count || getViewModeProductAmount(params.viewMode || selectedViewMode || defaultViewMode, settings),
  };
}
