import type { ProductComparisonPageState } from './types';
import { createReducer } from 'utils/redux';
import { buildPerfionComparisonTabsModel } from './util';
import { PageComponentNames } from 'behavior/pages';
import {
  PRODUCT_COMPARISON_REMOVED_PRODUCT,
  PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS,
  ProductComparisonAction,
} from 'behavior/productComparison/actions';

export default createReducer<ProductComparisonPageState | null, ProductComparisonAction>(null, {
  [PRODUCT_COMPARISON_REMOVED_PRODUCT]: onProductRemoved,
  [PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS]: onAllProductsRemoved,
});

function onProductRemoved(state: ProductComparisonPageState | null, action: { payload: { id: string; perfionTabsSettings: string[] } }) {
  if (state?.component !== PageComponentNames.ProductComparison)
    return state;

  const { products, perfionTabs } = state;
  const { id, perfionTabsSettings } = action.payload;
  const productToRemove = products.find(item => item.id === id);
  const remainingProducts = products.filter(item => item.id !== id);

  return {
    ...state,
    products: remainingProducts,
    perfionTabs: productToRemove
      ? buildPerfionComparisonTabsModel(perfionTabsSettings, remainingProducts)
      : perfionTabs,
  };
}

function onAllProductsRemoved(state: ProductComparisonPageState | null) {
  if (state?.component !== PageComponentNames.ProductComparison)
    return state;

  return {
    ...state,
    products: [],
    perfionTabs: [],
  };
}
