import linkStyles from 'components/primitives/links/Link.module.scss';
import configuratorStyles from './ProductConfigurator.module.scss';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { requestEditProductConfiguration } from 'behavior/productConfigurator';
import { useEffect, useState } from 'react';
import Tooltip from 'components/primitives/tooltip';
import { generateKey } from 'utils/helpers';

const ReconfigureButton = ({
  productConfigurator,
  productConfiguration,
  basketLineId,
  isConfiguratorAvailable,
  editProductConfiguration,
}) => {
  const [
    reconfigureText,
    disabledConfigureProductText,
  ] = useSanaTexts([
    'Reconfigure',
    'ButtonAltText_DisabledConfigureProduct',
  ], makeSimpleText).texts;

  const [loading, setLoading] = useState(false);
  const [componentId] = useState(generateKey);
  const [configurationFailed, setConfigurationFailed] = useState(false);

  useEffect(() => {
    if (productConfigurator
      && !productConfigurator.configuratorUrl
      && productConfigurator.updatedById === componentId) {
      setLoading(false);
      setConfigurationFailed(true);
    }
  }, [productConfigurator]);

  if (loading && productConfigurator && productConfigurator.configuratorUrl)
    setLoading(false);

  const handleEditConfiguration = () => {
    setLoading(true);
    editProductConfiguration(productConfiguration.id, basketLineId, componentId);
  };

  const isConfiguratorDisabled = !isConfiguratorAvailable;
  const isButtonDisabled = isConfiguratorDisabled || loading || configurationFailed;
  const isTooltipShown = isConfiguratorDisabled || configurationFailed;

  const button = (
    <button
      type="button"
      className={`${linkStyles.link} ${configuratorStyles.reconfigureButton}`}
      onClick={handleEditConfiguration}
      aria-disabled={isButtonDisabled}
      tabIndex={isButtonDisabled ? -1 : null}
    >
      {reconfigureText}
    </button>
  );

  return isTooltipShown
    ? <Tooltip title="" body={disabledConfigureProductText} sign={false}>{button}</Tooltip>
    : button;
};

ReconfigureButton.propTypes = {
  productConfigurator: PropTypes.shape({
    configuratorUrl: PropTypes.string,
    updatedById: PropTypes.string,
  }),
  productConfiguration: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  basketLineId: PropTypes.string.isRequired,
  isConfiguratorAvailable: PropTypes.bool.isRequired,
  editProductConfiguration: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  productConfigurator: state.page.productConfigurator,
  isConfiguratorAvailable: state.settings.product.isConfiguratorAvailable,
});

export default connect(mapStateToProps, { editProductConfiguration: requestEditProductConfiguration })(ReconfigureButton);