import styles from './LinkMenu.module.scss';
import PropTypes from 'prop-types';
import NavItem from './NavItem';
import { useLoadNavigation, useAriaLabel } from '../hooks';
import { ComponentGroup } from 'behavior/navigation/constants';
import { getThemeFontSizeClassName } from 'components/theme';
import { joinClasses } from 'utils/helpers';
import { NavigationMenuDisplayMode } from './constants';

const LinkMenuBlock = ({ model }) => {
  const [items, ready] = useLoadNavigation(ComponentGroup.LinkMenu, model.navigationGroupCode);
  const ariaLabel = useAriaLabel(model.navigationGroupCode);

  if (!ready)
    return null;

  const hasSubLevel = items.some(item => item.children?.length);
  const topLevelClassName = joinClasses(
    styles.topLevel,
    hasSubLevel && styles.columns,
    getThemeFontSizeClassName(hasSubLevel ? model.headingLinks_ThemeFontSize : model.links_ThemeFontSize),
  );
  const childrenClassName = getThemeFontSizeClassName(model.links_ThemeFontSize);

  const style = {
    '--link-fontFamily': model.links_FontFamily,
    '--link-fontColor': model.links_FontColor,
    '--link-allCaps': model.links_AllCaps ? 'uppercase' : null,
    '--link-hoverFontColor': model.links_HoverFontColor,
    '--headingLink-fontFamily': model.headingLinks_FontFamily,
    '--headingLink-fontColor': model.headingLinks_FontColor,
    '--headingLink-allCaps': model.headingLinks_AllCaps ? 'uppercase' : null,
    '--headingLink-hoverFontColor': model.headingLinks_HoverFontColor,
    '--link-flexBasis': hasSubLevel && model.displayMode === NavigationMenuDisplayMode.HorizontalList ? `${100 / Math.min(items.length, 12)}%` : null,
    '--column-flex-direction': hasSubLevel && model.displayMode === NavigationMenuDisplayMode.VerticalList ? 'column' : null,
    '--link-display': !hasSubLevel && model.displayMode === NavigationMenuDisplayMode.VerticalList ? 'block' : null,
  };

  return (
    <nav
      aria-label={ariaLabel}
      className={`${styles.container} ${model.links_ShowArrowInFront ? styles.showArrowInFront : ''}`}
      style={style}
    >
      <ul className={topLevelClassName}>
        {items.map((item, index) => <NavItem key={index} item={item} childrenClassName={childrenClassName} />)}
      </ul>
    </nav>
  );
};

LinkMenuBlock.propTypes = {
  model: PropTypes.shape({
    navigationGroupCode: PropTypes.string.isRequired,
    displayMode: PropTypes.number.isRequired,
    headingLinks_FontFamily: PropTypes.string,
    headingLinks_ThemeFontSize: PropTypes.string.isRequired,
    headingLinks_AllCaps: PropTypes.bool.isRequired,
    headingLinks_FontColor: PropTypes.string,
    headingLinks_HoverFontColor: PropTypes.string,
    links_FontFamily: PropTypes.string,
    links_ThemeFontSize: PropTypes.string.isRequired,
    links_AllCaps: PropTypes.bool.isRequired,
    links_FontColor: PropTypes.string,
    links_HoverFontColor: PropTypes.string,
    links_ShowArrowInFront: PropTypes.bool.isRequired,
  }).isRequired,
};

export default LinkMenuBlock;
