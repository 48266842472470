interface Sku {
  price?: number | null;
  listPrice?: number | null;
  staggeredPrices?: {
    quantityFrom: number | null;
    quantityTo: number | null;
    price: number | null;
    pricePerItem: number | null;
  }[] | null;
  customerStaggeredPrices?: {
    quantityFrom: number | null;
    quantityTo: number | null;
    price: number | null;
    pricePerItem: number | null;
  }[] | null;
  isOrderable?: boolean;
  inventory?: number | null;
}

interface Product extends Sku {
  variants?: Sku[] | null;
}

export function deleteProductCalculatedInfo<T extends Product>(product: T): T {
  const result = deleteSkuCalculatedInfo(product);

  if (result.variants)
    result.variants = result.variants.map(deleteSkuCalculatedInfo);

  return result;
}

function deleteSkuCalculatedInfo<T extends Sku>(sku: T): T {
  const result: T = { ...sku };
  delete result.price;
  delete result.listPrice;
  delete result.staggeredPrices;
  delete result.customerStaggeredPrices;
  delete result.isOrderable;
  delete result.inventory;

  return result;
}
