exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PreOrderBanner_wrapper{display:flex;flex-direction:column}.PreOrderBanner_wrapper.PreOrderBanner_center{align-items:center}.PreOrderBanner_container{display:flex;margin-top:.5em}.PreOrderBanner_container.PreOrderBanner_center{align-items:center;justify-content:center}.PreOrderBanner_container .PreOrderBanner_label{background-color:#ee2935;border-radius:5px;color:#fff;font-size:12px;line-height:normal;padding:3px 6px;text-align:center;white-space:nowrap}.PreOrderBanner_container .PreOrderBanner_label.PreOrderBanner_small{font-size:10px}.PreOrderBanner_container .PreOrderBanner_label.PreOrderBanner_big{font-size:14px}", ""]);

// exports
exports.locals = {
	"wrapper": "PreOrderBanner_wrapper",
	"center": "PreOrderBanner_center",
	"container": "PreOrderBanner_container",
	"label": "PreOrderBanner_label",
	"small": "PreOrderBanner_small",
	"big": "PreOrderBanner_big"
};