import createOffers, { ProductWithCalculatedInfo } from './offers';
import createProductImages, { ProductWithImages } from './productImages';
import createReview, { ProductWithReviews } from './review';
import createCategories, { ProductWithCategories } from './categories';
import { stripHtmlTagsAndFormatting } from '../helpers';
import { flatMap } from 'utils/helpers';
import { createUrl } from '../url';
import type { ProductTrackingOptions, CategoriesTexts } from './types';

interface Product extends ProductWithCalculatedInfo, ProductWithImages, ProductWithReviews, ProductWithCategories {
  relatedProductGroups?: Array<{ products: Product[] }>;
}

export default function createProduct(product: Product, options: ProductTrackingOptions, categoriesTexts: CategoriesTexts) {
  const { origin, brand } = options;
  const { id, title: name, url, description, relatedProductGroups } = product;

  const schema: Record<string, unknown> = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    mpn: id,
    url: createUrl(url, origin),
    brand: {
      '@type': 'Organization',
      'name': brand,
    },
    ...createCategories(product, categoriesTexts),
    ...createProductImages(product, options),
    ...createOffers(product, options),
    ...createReview(product),
  };

  if (description)
    schema.description = stripHtmlTagsAndFormatting(description);

  if (!relatedProductGroups || !relatedProductGroups.length)
    return schema;

  schema.isRelatedTo = flatMap(
    relatedProductGroups,
    ({ products }) => products,
    product => {
      const schema = createProduct(product, options, categoriesTexts);
      delete schema['@context'];

      return schema;
    },
  );

  return schema;
}
