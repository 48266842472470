import styles from './Faq.module.scss';
import stylesAdditional from 'components/objects/productList/page/PageTemplate.module.scss';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'components/primitives/grid';
import { renderHTML } from 'utils/render';

const FaqList = loadable(() => import(/*webpackChunkName:"faq"*/'./List'));
const LeftMenuNavigation = loadable(() => import('../navigation/leftMenu/LeftMenuNavigationBlock'));

const FaqPage = ({ heading, headerText, footerText, faqItems, showLeftMenu }) => {
  const htmlBlockStyles = `fr-view ${styles.htmlBlock}`;

  return (
    <Container className={`${stylesAdditional.plp} ${!showLeftMenu ? stylesAdditional.hideLeftColumn : ''}`}>
      <Row>
        {showLeftMenu &&
          <Col xs={{ size: 12, order: 'first' }} md={3} className={stylesAdditional.leftColumn}>
            <LeftMenuNavigation />
          </Col>
        }
        <Col xs={12} md={9} className={stylesAdditional.productList}>
          <h1>{heading}</h1>
          {headerText && <div className={htmlBlockStyles}>{renderHTML(headerText)}</div>}
          <FaqList faqItems={faqItems} />
          {footerText && <div className={htmlBlockStyles}>{renderHTML(footerText)}</div>}
        </Col>
      </Row>
    </Container>
  );
};

FaqPage.propTypes = {
  heading: PropTypes.string,
  headerText: PropTypes.string,
  footerText: PropTypes.string,
  faqItems: PropTypes.array,
  showLeftMenu: PropTypes.bool,
};

export default FaqPage;
