import {
    AddressValidationResult,
    USER_EDIT_PROCESSED,
    EditProfileAction,
  } from './actions';

type InitialState = Readonly<{
    addressValidationResult: AddressValidationResult | null;
    templateFields: any;
}>;

const initialState = {
    addressValidationResult: null,
    templateFields: null,
} as InitialState;
  
export default (state = initialState, action : EditProfileAction) => {
  switch (action.type) {
    case USER_EDIT_PROCESSED:
        return { ...state, ...action.payload };
    default:
        return state;
    }
};